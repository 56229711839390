import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FramesNavBar } from "../Howdoesscreen/HeadarNavbar/FramesNavbar";
import StyletransferModel from "../../Components/ModelComponents/styletransfer";
import ImgClassifyModel from "../../Components/ModelComponents/imgclassify";
import ChatGPTModel from "../../Components/ModelComponents/chatgpt";
import { isId } from "../../Storage(Methods)/storage";
import services from "../../API_SERVICES/services";
import ProtoType from "./PrototypeComponent";
import DataComponent from "./dataViewComponent";
import path from "path";
import axios from "axios";
import Compiler from "../TextEditor/Compiler";
import { isAuth } from "../../Storage(Methods)/storage";
import toast from "react-hot-toast";
import CallForData from "../../Components/ProjectDBSEComponents/CallForData";


export const Modelspage = () => {
  const navigate = useNavigate();
  const [perticularProject, setPerticularProject] = useState([]);
  const itemArray = [
    { name: "DISCOVER", value: "/decode" },
    { name: "FRAMES", value: "/CreateFrames" },
    { name: "BLUEPRINT", value: "/Howinputtab" },
    { name: "PROTOTYPE", value: "/modelspage" },
    { name: "REFLECTION", value: "/reflection" },
  ];
  
  const [parent, setParent] = useState({
    pId:'',
    project_title:'',
    DataCallTitle: "",
    title: "",
    message: '',
    dataTypes: '',
    call_for_data_fields: '',
    consent_and_permissions: '',
    core_question_author_name: '',
    core_question_how_this_sourced: '',
    core_question_where_did_this_come: '',
    optional_question_data: '',
    consent_optional_email: '',
    consent_donation_paid: '',
    callfordata_enabled:''
  });
  
  const [screenCall, setScreenCall] = React.useState(false);
 // Call the api for Get the Project DATA 
 const loaddata = () => {
  let apiname = "fetch-project-data";
  const project = true;
  services.get(apiname, project)
    .then((response) => {
      const tempArray = response.data.filter((item) => !item.video?.includes("dummyfileforTextUploadonly"));
      tempArray.forEach(async (item, index) => {
        tempArray[index].fileSize = await getFileSizeFromS3Url(item.video + "?not-from-cache-please");
      });
      setTimeout(() => {
        setProjectData(tempArray);
        setFileExtensionList(getExtensionList(response));
      }, 4000);
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
};


  const fetchPerticularProj = (projectid) => {
    const apiname1 = "fetch-project-id";
    services
      .getbasic(apiname1, projectid)
      .then((response) => {
        setPerticularProject(response?.data)
        setParent({
          pId:response.data?.pId,
          project_title:response?.data?.project_title,
          DataCallTitle: response?.data?.call_for_data_call_title,
          title: response?.data?.callfordata_title,
          message: response?.data?.callfordata_message,
          dataTypes: response?.data?.callfordata_type,
          call_for_data_fields: JSON.parse(response?.data?.call_for_data_fields),
          consent_and_permissions: response?.data?.consent_and_permissions,
          core_question_author_name: response?.data?.core_question_author_name,
          core_question_how_this_sourced: response?.data?.core_question_how_this_sourced,
          core_question_where_did_this_come: response?.data?.core_question_where_did_this_come,
          optional_question_data: JSON.parse(response?.data?.optional_question_data),
          callfordata_enabled:response.data?.callfordata_enabled,
          call_for_data_code: response?.data?.call_for_data_code,
        })

      }).catch((error) => {
        console.log(error);
      });
  }


  const loadmodel = (projectid) => {
    const apiname1 = "fetch-model";
    services
      .getbasic(apiname1, projectid)
      .then((response) => {
        setModels(response.data);
      }).catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    var projid = isId();
    if (projid !== -1) {
      loadmodel(projid);
    }
    loaddata();
    fetchPerticularProj(projid);
  }, []);

  const [models, setModels] = useState([]);
  const [projectData, setProjectData] = React.useState([]);
  const [fileExtensionList, setFileExtensionList] = React.useState([]);
  const [typedisplay, setTypedisplay] = useState(false);
  const [selecedType, setSelectedType] = useState([]);

  const [modeltypes, setModeltypes] = useState([
    {
      "name": "Sound",
      "selected": false,
    },
    {
      "name": "Text",
      "selected": false,
    },
    {
      "name": "Image",
      "selected": false,
    },
    {
      "name": "Video",
      "selected": false,
    }])
  const inputStyle = {
    width: "95%",
    borderRadius: "1000px",
    marginLeft: "10px",
    textAlign: "center",
    backgroundColor: " #C7CCB2",
    color: "#4E4E4E",
    opacity: "70%",
    border: "0.1 px solid #4E4E4E  ",
    height: "39px",
    marginRight: "20px",
  };


 

  // Fetch the file size from the s3 URL
  const getFileSizeFromS3Url = async (s3Url) => {
    return new Promise(async (res, rej) => {
      const response = await axios.head(s3Url);
      const fileSize = response.headers['content-length'];
      res((fileSize / 1024).toFixed(2).toString());
    });
  };

  const getExtensionList = (response) => {
    var ext = [];
    for (var i = 0; i < response?.data?.length; i++) {
      var filename = path.basename(response?.data[i]?.video);
      var extensions = (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename).toString() : undefined
      if (!ext.includes(extensions) && extensions !== undefined) {
        ext.push(extensions);
      }
    }
    return ext;
  }

  // const getExtensionList = (response) => {
  //   const extensions = response?.data
  //     ?.map((item) => {
  //       // Extract the signed URL
  //       const signedUrl = item?.video;
  //       if (signedUrl) {
  //         // Remove query parameters and extract the file extension
  //         const cleanUrl = signedUrl.split('?')[0];
  //         return cleanUrl.substring(cleanUrl.lastIndexOf('.') + 1).toLowerCase();
  //       }
  //       return undefined;
  //     })
  //     .filter((ext, index, self) => ext && self.indexOf(ext) === index); // Remove duplicates and undefined
  
  //   return extensions;
  // };
  





  const [query, setQuery] = useState("");

  let urlMap = new Map([
    ["chatgpt", "/chatgpt-demo"],
    ["mobilenet", "/Mobilenetdemo"]
  ]);
  const modelarray = [
    { "id": "1", "name": "IMAGE CLASSIFICATION", "desc": "TRAIN A MODEL TO CLASSIFY OBJECTS", "li": "/Mobilenetmodel", "link": "/Mobilenetdemo", "type": "Image", "subtype": "ML5" },
    { "id": "2", "name": "SOUND CLASSIFICATION", "desc": "TRAIN A MODEL TO CLASSIFY SOUND", "link": "/Sound", "type": "Sound", "subtype": "TF" },
    { "id": "3", "name": "mfcc", "desc": "Audio RECOGNITION.", "link": "/Comingsoon", "type": "Audio", "subtype": "" },
    { "id": "4", "name": "STYLE TRANSFER", "desc": "TRANSFER STYLE OF ONE IMAGE TO THE CONTENT OF ANOTHER IMAGE", "link": "/styletransfer", "type": "Image", "subtype": "TF" },
    { "id": "5", "name": "POSENET POSE CLASSIFICATION", "desc": "TRAIN A MODEL TO CLASSIFY POSES", "link": "/Pose", "type": "Video", "subtype": "ML5" },
    { "id": "6", "name": "GANSYNTH", "desc": "gENERATE HIGH-FIDELITY AUDIO WITH gENERATIVE ADVERSARIAL NETWORKS (GANs)", "link": "/Comingsoon", "type": "Audio", "subtype": "magenta" },
    { "id": "7", "name": "DEEPFACE", "desc": "FACE DETECTION", "link": "/model-execute", "type": "Video", "subtype": "magenta" },
    { "id": "8", "name": "GPT-3.5-TURBO-1106", "desc": "FINETUNE CHATGPT", "link": "/chatgpt-demo", "type": "Text" },
    { "id": "9", "name": "DALL-E-3", "desc": "Generate images", "link": "/dalle", "type": "Image" },
  ];
  const search_parameters = ["name", "desc"];
  function search(data) {
    const filteredDATA = data.filter((node) =>
      selecedType.length > 0
        ?
        selecedType.some((filterTag) =>
          node['type'].includes(filterTag)
        )
        : data)
    return filteredDATA.filter(
      (item) =>
        search_parameters.some((parameter) =>
          item[parameter].toString().toLowerCase().includes(query)
        )
    );
  }
  const handlechange = (index) => {
    setSelectedType([]);
    const newtype = [...modeltypes];
    newtype[index].selected = !modeltypes[index].selected;
    setModeltypes(newtype);
    let arr = [];
    for (let i = 0; i < newtype.length; i++) {
      if (newtype[i].selected) {
        arr.push(newtype[i].name);
      }
    }
    setSelectedType(arr);
  };
  const [component, setComponent] = useState(0)
  const clickHandler = (component) => {
    switch (component) {
      case "1":
        setComponent(1);
        break;
      case "2":
        setComponent(2)
        break;
      case "3":
        setComponent(3);
        break;
      case "4":
        setComponent(4);
        break;
      case "5":
        setComponent(5);
        break;
      case "6":
        setComponent(6);
        break;
      case "7":
        setComponent(7);
        break;
      case "8":
        setComponent(8);
        break;
      case "9":
        setComponent(9);
        break;
      default:
        setComponent(0);
        break;
    }
  };
  const closeModel = () => {
    setComponent(0);
  }

  // const handleModelView = () => {
  //   setButtonState({
  //     dataView: false,
  //     modelsView: true
  //   })
  // }
  const handleDataView = () => {
    document.getElementById("dataViewBox").scrollIntoView({
      behavior: "smooth"
    });
  }
  const handleTextEditorView = () => {
    document.getElementById("textEditorViewBox").scrollIntoView({
      behavior: "smooth"
    });
  }

  const oncalldataOpen = () => {
    // if (!callenabled) {
    //   setValues({
    //     ...values, title: '',
    //     message: '',
    //     type: []
    //   });
    //   setTogglecall(false);
    // }
    // else {
    //   setTogglecall(true);
    // }
    setScreenCall({ screenCall: !screenCall })
  }

  const onSubmitData = (states) => {
    const joinedStr = states?.dataTypes.map((list) => list.value).join(",")
    const data = {
      pId: perticularProject?.pId,
      msg: states?.titleMessage,
      type: joinedStr, // Data type is Array of object in String
      title_tagline: states?.title,
      data_call_title: states?.DataCallTitle,
      call_for_data_fields: JSON.stringify(states?.fieldsData), 
      core_question_author_name: states?.author,
      core_question_where_did_this_come: "",
      core_question_how_this_sourced: states?.howWasThisSourced,
      optional_question_data: JSON.stringify(states?.optionalData), 
      consent_and_permissions: `${states?.permissionOne};${states?.permissionTwo};${states?.permissionThree}`,
      contribution_types:JSON.stringify(states?.contributionTypes)
    };
    const apiname = '/activate-callfordata'
    services
      .post(apiname, data)
      .then((response) => {
        setScreenCall(!screenCall);
        toast.success(response.data.message);
        fetchPerticularProj(data.pId)
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  }

  const unPublishCallForData = (data) => {
    services.put("unpublish-callfordata", data)
      .then((response) => {
        toast.success(response?.data?.message);
        fetchPerticularProj(data?.pId);
        setScreenCall(!screenCall);
      }).catch((err) => {
        console.log(err);
      });
  }

  const deleteCallForData = (data) => {
    services.delete(`delete-callfordata/${data}`)
      .then((response) => {
        toast.success(response?.data?.message);
        fetchPerticularProj(data);
        setScreenCall(!screenCall);
      }).catch((err) => {
        console.log(err);
      });
  }

  const handleGenerateLink = (data) => {
    services.post("generatelink-callfordata",data)
    .then((response) => {
      toast.success(response?.data?.message);
    }).catch((err) => {
      console.log(err);
    });
  }


  return (
    <>
      <FramesNavBar labels={itemArray} isActive={"PROTOTYPE"} />
      <div
        className="ms-3 mb-3"
        style={{ width: "98%" }}
      >
        <div className="prototypeFont mt-3" >
          <h1>Prototype</h1>
        </div>
      </div>

      <ProtoType
        dataView={handleDataView}
        textEditor={handleTextEditorView}
        modelData={models?.data}
        selectedType={selecedType}
        search_parameters={search_parameters}
        query={query}
        onClick={(model_type) => { navigate(urlMap?.get(model_type), { replace: true, state: { source: "prototype" } }) }}
      />

      {/* {models?.data?.length > 0 ? (
        <div className="blackmodelbox-list my-5">
          {search(models?.data)?.map((d) => (
            <div style={cardStyle}>
              <div key={d.pId + 1}>
                <h4 style={{ fontSize: "25px", textTransform: "uppercase" }}>{d.name}</h4>
                <h4
                  style={{
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    fontSize: "25px",
                    textOverflow: "ellipsis",
                    textTransform: "uppercase"
                  }}
                >
                  {d.model_type}
                </h4>
              </div>
              <div style={{ marginTop: "150px" }}>
                <p
                  style={{ textDecoration: "none", color: "rgba(78, 78, 78, 0.7) " }}
                  onClick={() => navigate(urlMap?.get(d.model_type), { replace: true, state: { source: "prototype" } })}
                >
                  DEMO
                </p></div>
            </div>
          ))}
        </div>
      ) :
        <div className="blackmodelbox"
          style={{
            fontSize: "14px",
            color: "#C7CCB2",
            textAlign: "center",

          }}>
          PROJECT MODELS WILL APPEAR HERE
        </div>
      } */}

      <br></br>
      {component === 0 && <> </>}
      {component === 1 && <ImgClassifyModel closeModel={closeModel} />}
      {component === 4 && <StyletransferModel closeModel={closeModel} />}
      {component === 9 && <ChatGPTModel closeModel={closeModel} />}


      <div
        className="ms-3 mb-3"
        style={{ borderBottom: "2px solid #4E4E4E", width: "98%" }}
      >
        <div className="prototypeFont mt-3" >
          <h1>MODELS</h1>
        </div>
      </div>

      <div className="row p-3">
        <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6">
          <input
            type="search"
            placeholder="SEARCH MODELS"
            className="searchinput"
            style={inputStyle}
            onChange={(e) => setQuery(e.target.value)}
          >
          </input>
        </div>
        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
          {typedisplay ?
            <>
              <div className='Invisible' onClick={() => setTypedisplay(false)}></div>
              <div className="modeltypelist">
                <span style={{ alignSelf: "center" }}>INPUT</span>
                {modeltypes?.map((mt, index) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          handlechange(index);
                        }}
                        key={index} className={mt.selected ? 'modeltypesubselected' : 'modeltypesub'}>
                        <span>{mt.name}</span>
                        {mt.selected ? <><i className="fas fa-check" ></i></> : <><i className="fas fa-plus" ></i></>}
                      </div>
                    </>

                  );
                })}

              </div></> : <button className="dropdown-toggle typeinput" data-bs-toggle="dropdown" aria-expanded="false"
                onClick={() => setTypedisplay(true)}
              >
              INPUT
            </button>
          }
        </div>
      </div>
      <br></br>
      <div className="scaffold-container">
        {search(modelarray)?.map((model, index) => (
          <div className="modellboxx hover-text" onClick={() => clickHandler(model.id)}>
            <p
              style={{
                fontSize: "25px",
                color: "#4E4E4E",
                margin: "0 0 10px 10px",
                lineHeight: "32px",
                fontWeight: "400",
                textTransform: "uppercase",
                whiteSpace: 'pre-line'
              }}
            >
              {model.name}
            </p>
            <p
              style={{
                fontSize: "25px",
                color: "#4E4E4E",
                margin: "0 0 10px 10px",
                lineHeight: "25px",
                fontWeight: "400",
                textTransform: "uppercase",
              }}
            >
              {model.subtype}
            </p>
            <p
              style={{
                fontSize: "16px", color: "rgba(78, 78, 78, 0.7)", margin: "10px 0 0 10px", lineHeight: "21px",
                fontWeight: "400", textTransform: "uppercase", width: "70%"
              }}
            >
              <p
                style={{ textDecoration: "none", color: "rgba(78, 78, 78, 0.7) " }}
                onClick={() => navigate(model.li, { replace: true, state: { source: "prototype" } })}
              >
                {model.desc}
              </p>
            </p>
            {model.id === "5" || model.id === "2" ?
              <p
                style={{ textDecoration: "none", color: "rgba(78, 78, 78, 0.7) " }}
                onClick={() => navigate(model.li, { replace: true, state: { source: "prototype" } })}
                className="text-center scrolling-text"
              >
               <h5>! Under development !</h5>
              </p>
              : null
            }
            <p
              style={{
                fontSize: "25px",
                color: "rgba(78, 78, 78, 0.7)",
                left: "12px",
                bottom: "0",
                position: "absolute",
                lineHeight: "12px"
              }}
            >
              <p

                style={{ textDecoration: "none", color: "rgba(78, 78, 78, 0.7) " }}
                onClick={() => navigate(model.link, { replace: true, state: { source: "prototype" } })}
                className="text-hover"
              >
                DEMO
              </p>
            </p>
          </div>
        ))}
      </div>

      <div
        id="dataViewBox"
        className="ms-3 mb-3"
        style={{ borderBottom: "2px solid #4E4E4E", width: "98%" }}
      >
        <div className="prototypeFont mt-3" style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
          <h1>DATA</h1>
          {isAuth().id === perticularProject?.user_id ? (
            <button
              type="button"
              className={
               parent && parent?.callfordata_enabled === "Y"
                ? "publicbtnActive me-2" : "publicbtn me-2"
              }
              onClick={oncalldataOpen}
            >
              +  DATA CALL
            </button>
          ) : null}
        </div>
      </div>
      {/* Call For DATA View Here */}
      {!screenCall ? null : (
        <CallForData
          uploadData={(states) => onSubmitData(states)}
          loadShareData={(data) => handleGenerateLink(data)}
          onClick={screenCall}
          parentStates={parent}
          projDesc={perticularProject?.project_desc}
          unPublish = {(data) => unPublishCallForData(data)}
          deleteCall = {(data) => deleteCallForData(data)}
          closeModal={(value) => {
            setScreenCall(value)
          }}
        />
      )}
      <DataComponent Data={projectData} ExtensionList={fileExtensionList} UploadButton={true} fetchData={(value) => value ? loaddata() : null} perticularProject={parent} />
   

      <div
        id="textEditorViewBox"
        className="ms-3 mb-3"
        style={{ borderBottom: "2px solid #4E4E4E", width: "98%" }}
      >
        <div className="prototypeFont mt-3" >
          <h1>Text Editor</h1>
        </div>
      </div>
      <Compiler />
    </>
  );
};

export default Modelspage;