import React from "react";
import "./CallForData.css";
import CustomDropdown from "./CustomDropdown";
import { CustomInputField } from "./CustomInputField";
import MultiSelectTextInput from "./multiSelectDropDown";
import toast from "react-hot-toast";
import { validateInput } from "../../utils/general";
import option from "../../Assets/Images/options.png";
import Share from "./Share";
import Trace from "./Trace";


const options = [
  { name: "Audio - Upload", selected: false, value: "MP3" },
  { name: "Audio - Record", selected: false, value: "MP3" },
  { name: "TEXT - Write-In", selected: false, value: "TXT" },
  { name: "TEXT - Upload", selected: false, value: "TXT" },
  { name: "Image - Upload", selected: false, value: "PNG" },
  { name: "Image - Draw", selected: false, value: "SVG" },
  { name: "3D - Upload", selected: false, value: "obj" },
  { name: "Video - Upload", selected: false, value: "MP4" },
];

const dataTypes = [
  { name: "AUDIO", value: "MP3" },
  { name: "TEXT", value: "TXT" },
  { name: "IMAGE", value: "PNG,JPG,SVG" },
  { name: "Video", value: "Video" },
  { name: "3D", value: "3D (obj)" },
];
const color = {
  color: "rgba(78, 78, 78, 0.7)",
  fontSize: "12px",
  // fontFamily:"DM Mono"
};

const CallForData = ({
  parentStates,
  callenabled,
  uploadData,
  closeModal,
  projDesc,
  unPublish,
  deleteCall,
  loadShareData
}) => {
  const [state, setState] = React.useState({
    DataCallTitle: "",
    title: "",
    titleMessage: "",
    isProjectDescriptionChecked: false,
    fieldsData: [],
    dataTypes: [],
    optionalData: [],
    author: "",
    whereDidThisComeFrom: "",
    howWasThisSourced: "",
    permissionOne: `I consent to this data being used according to the data settings
    of this project, for as long as the project chooses to store the
    data (read about our data policy and future plans here)`,
    permissionTwo: "Can we follow-up with you about your upload?",
    permissionThree: "",
    consentsAndPermissions: [],
    fieldCount: 1,
    optionalFieldCount: 1,
    crudOperation: false,
    enableShare: false,
    trace: false,
    contributionTypes: []
  });

  const handleOptionToggle = (option) => {
    setState((prev) => ({
      ...prev,
      contributionTypes: prev.contributionTypes.includes(option)
        ? prev.contributionTypes.filter((item) => item !== option)
        : [...prev.contributionTypes, option],
    }));
  };

  const [showOptions, setShowOptions] = React.useState(false);

  const handleOptionClick = () => {
    setShowOptions(!showOptions);
  };

  React.useEffect(() => {
    if (
      parentStates?.callfordata_enabled === "Y" ||
      parentStates?.DataCallTitle
    ) {
      setState({ ...state, crudOperation: true });
    }
  }, []);

  const [fieldTempData, setFieldTempData] = React.useState({
    question: "",
    dropDownValue: "",
  });

  const [optionalQuestions, setOptionalQuestions] = React.useState({
    question: "",
    value: "",
  });

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [screenCount, setScreenCount] = React.useState(
    parentStates.callfordata_enabled === "Y" || parentStates.dataTypes ? 6 : 1
  );
  // const [isChecked, setIsChecked] = React.useState(false);
  // const [isAdvance, setisAdvance] = React.useState(false);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const projectName = sessionStorage.getItem("projectName");

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const changeScreen = () => {
    const isValid = () =>
      validateInput(state.DataCallTitle, "DataCallTitle") &&
      validateInput(state.title, "title") &&
      validateInput(state.titleMessage, "titleMessage") &&
      validateInput(state.dataTypes.join(""), "dataTypes");

    // Check if inputs are valid
    if (!isValid()) {
      return; // Do not proceed if validation fails
    }
if (screenCount === 2) {
  setScreenCount(6);
}
    if (screenCount === 3) {
      if (fieldTempData.question && fieldTempData.dropDownValue) {
        setState({
          ...state,
          fieldsData: [
            ...state.fieldsData,
            {
              question: fieldTempData.question,
              selectedValue: fieldTempData.dropDownValue,
            },
          ],
          fieldCount: state.fieldCount + 1,
        });
        setFieldTempData({ question: "", dropDownValue: "" });
      }
    } else if (screenCount === 4 && optionalQuestions.question) {
      setState({
        ...state,
        optionalData: [
          ...state.optionalData,
          {
            question: optionalQuestions.question,
            value: optionalQuestions.value,
          },
        ],
        optionalFieldCount: state.optionalFieldCount + 1,
      });
      setOptionalQuestions({ question: "", value: "" });
    }
    setScreenCount(screenCount + 1); // Otherwise, increment screenCount

  };

  const handleNext = () => {
    setScreenCount(screenCount + 1);
  }
  const handleLastScreen = () =>{
    setScreenCount(6);
  }

  const handleBack = () => {
    if (screenCount === 1) {
      closeModal(false);
    } else if (screenCount === 6) {
      setScreenCount(1);
      setState({ ...state, crudOperation: false });
    } else {
      setScreenCount(screenCount - 1);
    }
  };

  // Send the states of Call For Data Settings on server
  const saveCallForData = () => {
    uploadData(state);
    // setState({...state,crudOperation:true})
  };
  const data = {
    pId: parentStates?.pId,
  };

  //send project ID to parent
  const unPublishCallForData = () => {
    unPublish(data);
    setShowOptions(false);
  };

  //send project ID to parent
  const deleteCallForData = () => {
    deleteCall(parentStates?.pId);
    setShowOptions(false);
  };

  // const toggleChecked = () => {
  //   setIsChecked(!isChecked);
  //   setState((prevState) => ({
  //     ...prevState,
  //     consentsAndPermissions: isChecked ? [] : ["checked"],
  //   }));
  // };

  // const setPermissions = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.value });
  // };

  const setStateData = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleQuestionsData = (e, index) => {
    const { value } = e.target;
    setFieldTempData({ ...fieldTempData, question: value }); //Set the Question in Temp State
  };

  const handleDropdownChange = (selectedOption, index) => {
    setFieldTempData({ ...fieldTempData, dropDownValue: selectedOption });
  };

  const handleDataTypeChange = (selectedValues) => {
    setState({ ...state, dataTypes: selectedValues });
  };

  //   const handleDataTypeChange = (selectedValues) => {
  //     const imageOptions = ["IMAGE (PNG)", "IMAGE (JPG)", "IMAGE (SVG)"];
  //     let lastImageOption = null;

  //     // Filter selected values to allow only one image type
  //     const filteredValues = selectedValues.filter(option => {
  //         if (imageOptions.includes(option.name)) {
  //             lastImageOption = option;
  //             return false;
  //         }
  //         return true;
  //     });

  //     // If there is a selected image option, add it to the filtered values
  //     if (lastImageOption) {
  //         filteredValues.push(lastImageOption);
  //     }

  //     // Update state with the filtered values
  //     setState({ ...state, dataTypes: filteredValues });
  // };

  const addDataFields = () => {
    let jsonObj = {
      question: fieldTempData.question,
      selectedValue: fieldTempData.dropDownValue,
    };
    // Add jsonObj in existing array data of fieldData
    setState({
      ...state,
      fieldsData: [...state.fieldsData, jsonObj],
      fieldCount: state.fieldCount + 1,
    });
    setFieldTempData({ question: "", dropDownValue: "" });
  };

  const addOptionalFields = () => {
    let jsonObj1 = {
      question: optionalQuestions.question,
      value: "",
    };
    // Add jsonObj in existing array data of optionalData
    setState({
      ...state,
      optionalData: [...state.optionalData, jsonObj1],
      optionalFieldCount: state.optionalFieldCount + 1,
    });
    setOptionalQuestions({ question: "" });
  };

  const handleOptionalQuestionsData = (e, index) => {
    const { value } = e.target;
    setOptionalQuestions({ ...optionalQuestions, question: value }); //Set the Question in Temp State
  };
  const iframeToCopy = `${process.env.REACT_APP_API_SHARE_URL}/embedPage/${parentStates?.pId}`

  const copyIframeToClipboard = () => {
    const iframe = document.createElement('iframe');
    iframe.src = iframeToCopy;
    iframe.width = '100%';
    iframe.height = '100%';
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'microphone; camera; geolocation');
    iframe.setAttribute('style', 'position: fixed; top: 0; left: 0; width: 100%; height: 100%; border: none;');

    document.body.appendChild(iframe);
    const iframeHtml = iframe.outerHTML;
    document.body.removeChild(iframe);

    navigator.clipboard.writeText(iframeHtml);
    toast.success("iframe copied!")
  };

  const initialScreen = () => {
    return (
      <>
        <div className="initial-screen-container" >
          <p style={{ fontSize: "14px", color: "#4E4E4EB2", width: "100%" }}>
            A Call for Data will make a public posting on POIETO Data.
          </p>
          <div className="headerInputs">
            <label
              style={{
                display: "flex",
                // paddingLeft: "25px",
                width: "100%",
                color: "rgba(0, 0, 0, 0.54)",
                fontWeight: "400",
                fontSize: "16px"
              }}
            >
              Data Call Title
            </label>
            <input
              // id="DataCall"
              name="DataCallTitle"
              type="text"
              value={state.DataCallTitle}
              placeholder="Enter info..."
              // style={{ fontStyle: "italic", border: "none" }}
              onChange={(event) => setStateData(event)}
            />
            <label
              style={{
                display: "flex",
                width: "100%",
                color: "rgba(0, 0, 0, 0.54)",
                fontWeight: "400",
                fontSize: "16px"
              }}
            >
              Data Call Tagline - Describe the data you are looking for.
            </label>
            <input
              name="title"
              type="text"
              value={state.title}
              placeholder="Enter info..."
              onChange={(event) => setStateData(event)}
            />
            <label
              style={{
                display: "flex",
                // paddingLeft: "25px",
                width: "100%",
                color: "rgba(0, 0, 0, 0.54)",
                fontWeight: "400",
                fontSize: "16px"
              }}
            >
              Project Description - Describe how you are planning to use the
              data.
            </label>
            <div className="cfd-ProjDescSection">
              <div className="cfd-checkbox">
                <label className="projectdescriptioncheckbox">
                  <input
                    type="checkbox"
                    checked={state.isProjectDescriptionChecked}
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      setState((prevState) => ({
                        ...prevState,
                        isProjectDescriptionChecked: isChecked,
                        titleMessage: isChecked ? projDesc : "",
                      }));
                      if (isChecked && !projDesc) {
                        setState((prevState) => ({
                          ...prevState,
                          isProjectDescriptionChecked: false,
                          titleMessage: isChecked ? projDesc : "",
                        }));
                        toast.error(
                          "Project description cannot be empty. Please write a project description."
                        );
                      }
                    }}
                    style={{ display: "none" }}
                  />
                  <span className="checkmark"></span>
                  USE PROJECT DESCRIPTION
                </label>
              </div>
            </div>
            <input
              name="titleMessage"
              type="text"
              value={
                state.isProjectDescriptionChecked ? null : state.titleMessage
              }
              style={
                state.isProjectDescriptionChecked ? { opacity: 0.5 } : {}
              }
              placeholder="Enter info..."
              disabled={state.isProjectDescriptionChecked}
              onChange={(event) => setStateData(event)}
            />
          </div>
        </div>

        <div className="dataType-selection">
          <div>
            <label
              style={{
                display: "flex",
                width: "100%",
                color: "rgba(0, 0, 0, 0.54)",
                fontWeight: "400",
              }}
            >
              Data Type(s) - Select type of data to appear on the data call
              posting. This can be more than one type.
            </label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "5px",
            }}
          >
            <MultiSelectTextInput
              options={dataTypes}
              placeholder="TYPE"
              onChange={handleDataTypeChange}
              dropdownStyle={{ width: "200px", maxHeight: "35px" }}
              inputStyle={{
                padding: "10px",
                backgroundColor: "transparent",
                width: "40px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            />

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {state.dataTypes?.map((value, index) => (
                <div key={index} className="cfd-selectedvalues">
                  <p>{value.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="contribution-type">
          <label style={{
            color: "rgba(0, 0, 0, 0.54)",
            fontWeight: "400",
            marginBottom: "15px"
          }}>Contribution Type</label>
          <div className="contribution-option">
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={state.contributionTypes.includes("Donation")}
                onChange={() => handleOptionToggle("Donation")}
              />
              <span className="Contribution-checkmark"></span>
              <div>
                <label style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "rgba(0, 0, 0, 0.54)"
                }}>Donation</label>
                <label style={{ fontSize: "14px", fontWeight: "300", color: "rgba(0, 0, 0, 0.7)" }}>Contributor consensually donates their data to the project</label>
              </div>
            </label>
          </div>
          <div className="contribution-option">
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={state.contributionTypes.includes("Monetization")}
                onChange={() => handleOptionToggle("Monetization")}
              />
              <span className="Contribution-checkmark"></span>
              <div>
                <label style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "rgba(0, 0, 0, 0.54)"
                }}>Future Pathway to Monetization</label>
                <label style={{ fontSize: "14px", fontWeight: "300", color: "rgba(0, 0, 0, 0.7)" }}>Contributor donates their data in exchange for</label>
              </div>
            </label>
          </div>
        </div>
      </>
    )
  }

  const flowSelectionScreen = () => {
    return (
      <>
        <div className="flow-selection-container">
          <p style={{ color: "rgba(78, 78, 78, 0.7)" }}>
            A Call for Data will make a public posting on POIETO Data.
          </p>
          <div className="data-call-container">
            <div className="standard-container" onClick={handleLastScreen}>
              <h3>Standard Data Call</h3>
              <div class="plus-icon"></div>
              <h3 className="description">Continue to publish a standard Data Call. An ideal option for calls looking for specific file types and open-ended research.</h3>
            </div>

            <div className="data-call-card" onClick={handleNext}>
              <h3>Advanced</h3>
              {["Prompt 1", "Prompt 2"].map((label, index) => (
  <div className="advanced-prompt" key={index}>
    <label>{label}</label>
    <div className="prompt-input">
      <div className="advanced-plus-icon"></div>
    </div>
  </div>
))}
              <div className="Advanced-hover-text" >
              <label className="plus-icon" ></label>
              <h3 className="description" style={{textAlign:"start"}}>Further customize your data call. Add additional form fields and modify provenance and consent parameters.</h3>
              </div>
             
            </div>
          </div>

        </div>

      </>
    )
  }
  const screenOne = () => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* <p style={{ fontSize: "14px", marginTop: "15px" }}>
            Here you can further customize your call for data and add additional
            inputs within a single form contribution.
          </p> */}
        </div>
        {state.fieldsData.length > 0
          ? state.fieldsData.map((item, index) => (
            <div className="fieldRowView">
              <label>Field {index + 1}</label>
              <input
              className="Custominput"
                name="question"
                type="text"
                value={item.question}
                placeholder="Type your prompt"
                disabled={true}
              />
             
              {/* <CustomInputField
                name="question"
                type="text"
                placeholder="Type your prompt"
                value={item.question}
                className="DatacallInputFieldstyles"
            labelStyles={{margin: "8px",fontSize:"16px" }}
                disabled={true}
                label={`Field ${index + 1}`}
              /> */}

              <CustomDropdown
                options={options}
                selectedValue={item.selectedValue}
                placeholder="Select an option"
                dropdownStyle={{ width: "200px" }}
                inputStyle={{
                  padding: "10px",
                  fontFamily: "DM Mono",
                  fontSize: "16px",
                  // border: "1px solid #ccc",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                   lineHeight:"18px"

                }}
                onChange={(selectedOption) =>
                  handleDropdownChange(selectedOption, 1)
                }
              />
            </div>
          ))
          : null}
        <div className="fieldRowView">
          <label style={{fontSize:"16px"}}>Field {state.fieldCount}</label>
          <input className="Custominput"
            name="question"
            type="text"
            placeholder="Type your prompt"
            value={fieldTempData.question}
            onChange={(e) => handleQuestionsData(e)}
            
          />
          {/* <CustomInputField
            name="question"
            type="text"
            className="CustomInputFieldstyles"
            labelStyles={{margin: "8px",fontSize:"16px" }}
            placeholder="Type your prompt"
            value={fieldTempData.question}
            onChange={(e) => handleQuestionsData(e)}
            label={`Field ${state.fieldCount}`}
          /> */}
          <CustomDropdown
            options={options} // List of Array
            selectedValue={fieldTempData.dropDownValue}
            placeholder="Input"
            dropdownStyle={{ width: "200px" }}
            inputStyle={{
              padding: "10px",
              fontFamily: "DM Mono",
                  fontSize: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              lineHeight:"18px"
            }}
            onChange={(selectedOption) =>
              handleDropdownChange(selectedOption, 0)
            }
          />
        </div>
        <div className="basicRow">
          <div className="plusButton" onClick={addDataFields}>
            &#43;
          </div>
        </div>
      </React.Fragment>
    );
  };

  const screenTwo = () => {
    return (
      <React.Fragment>
        <div className="headingScreenTwo">
          <p style={{ fontSize: "14px" }}>
            Provenance is the chronology of an object’s ownership.
          </p>
          <p style={{ fontSize: "14px" }}>
            These required questions will appear to contributors after uploading
            files to your <br />
            data call:
          </p>
        </div>
        <div
          style={{
            border: "2px dotted #4E4E4EB2",
            width: "100%",
            padding: "20px 21px",
          }}
        >
          <div className="basicRow" style={{ width: "100%" }}>
            {/* <CustomLabel label="AUTHOR" /> */}
            <CustomInputField
            // name="question"
            type="text"
            className="DatacallInputFieldstyles"
            labelStyles={{margin: "8px",fontSize:"16px" }}
            placeholder="Enter info..."
            label="AUTHOR"
            disabled={true}
          />
          </div>
          <div className="basicRow" style={{ width: "100%" }}>
            {/* <CustomLabel label="HOW WAS THIS SOURCED?" /> */}
            <CustomInputField
            // name="question"
            type="text"
            className="DatacallInputFieldstyles"
            labelStyles={{margin: "8px",fontSize:"16px" }}
            placeholder="Enter info..."
            label="HOW WAS THIS SOURCED?"
            disabled={true}
          />
          </div>
        </div>
        <div className="headingScreenTwo" style={{ marginTop: "20px" }}>
          <p>Optional</p>
        </div>
        {state.optionalData.length > 0
          ? state.optionalData.map((item, index) => (
            <div className="fieldRowView" style={{ height: "90px" }}>
              <label>Question {index + 1}</label>
              <input
                name="question"
                type="text"
                value={item.question}
                placeholder="Type your question"
                disabled={true}
                style={{border:"none"}}
              />
            </div>
          ))
          : null}
        <div className="fieldRowView" style={{ height: "90px" }}>
          <label>Question {state.optionalFieldCount}</label>
          <input
            name="question"
            type="text"
            placeholder="Type your question"
            value={optionalQuestions.question}
            onChange={(e) => handleOptionalQuestionsData(e)}
            style={{border:"none"}}
          />
        </div>
        <div className="basicRow">
          <div className="plusButton" onClick={addOptionalFields}>
            &#43;
          </div>
        </div>
      </React.Fragment>
    );
  };

  const screenThree = () => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* <p style={{ fontSize: "24px" }}>Consent & Permissions</p> */}
        </div>
        <div className="headingScreenTwo">
          <p style={{ fontSize: "14px" }}>What will appear to contributors:</p>
        </div>
        <div className="basicRow">
          {/* <h6 className="headingScreenThree">CONSENT AND PERMISSIONS:</h6>
          <p className="headingScreenThree">
            PLEASE SELECT ALL THAT APPLY.PLEASE SEND ANY QUESTIONS TO
            DATA@POIETO.COM
          </p> */}
          {/* <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="permissionOne"
              onChange={setPermissions}
              value="I CERTIFY I HAVE THE PERMISSIONS TO SHARE THIS FILE"
              style={{ marginTop: "0.8rem" }}
            />
            <label
              className="form-check-label"
              htmlFor="gridCheck1"
              style={{
                fontSize: "12px",
                color: "#4E4E4EB2",
                marginTop: "0.8rem",
              }}
            >
              I CERTIFY I HAVE THE PERMISSIONS TO SHARE THIS FILE
            </label>
          </div> */}
          <div
            style={{
              border: "1px dotted #4E4E4EB2",
              width: "100%",
              padding: "30px 10px",
            }}
          >
            <div className="form-check ">
              <label
                className="consent-label"
               
              ><div className="customSquare"></div>
                I consent to this data being used according to the data settings of this project, for as long as the project chooses to store the data.
              </label>
            </div>
            <div className="form-check mb-2 mt-4">
              <label
                className="consent-label"
               
              
              ><div className="customSquare2"></div>
                Can we follow-up with you about your upload?
              </label>
            </div>
          </div>
          <div style={{ marginTop: "25px" ,fontFamily:"DM Sans"}}>
            <p style={color}>
              Email data@poieto.com with any questions or requests.
            </p>
          </div>
          {/* <div className="toggleContainer">
            <div
              className={`custom-toggle-slider ${isChecked ? "checked" : ""}`}
              onClick={toggleChecked}
            >
              <div className="slider"></div>
            </div>
          </div> */}
        </div>
      </React.Fragment>
    );
  };

  const handleShare = () => {
    setState(
      { ...state, enableShare: true, })
    setShowOptions(!showOptions);
  }

  const onShareData = (states) => {
    loadShareData(states)
    setState({ ...state, enableShare: false })
  }

  // const showTrace = () => {
  //   setState(
  //     { ...state, trace: true, })
  //   setShowOptions(!showOptions);
  // }

  const screenFour = () => {
    return (
      <React.Fragment>
        {state.enableShare &&
          <Share
            projectName={projectName}
            pId={parentStates?.pId}
            uploadShareData={(states) => onShareData(states)}
            code={parentStates?.call_for_data_code}
            closeShare={(value) => {
              setState({ ...state, enableShare: value })
            }} />
        }
        {state.trace &&
          <Trace
            closeTrace={(value) => {
              setState({ ...state, trace: value })
            }} />
        }

        <div className="FourthScreencontainer">
          <i
            className="far fa-times-circle fa-lg close-icon"
            onClick={() => {
              closeModal(false);
            }}
            style={{ color: "grey" }}
          ></i>
          <div className="FSC-options">
            <p
              style={{
                fontSize: "20px",
                color: "rgba(0, 0, 0, 0.54)",
                fontWeight: "400",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Call for Data
            </p>
            {state?.crudOperation && (
              <div style={{ position: "relative" }}>
                <img
                  alt="menu"
                  src={option}
                  style={{ height: "16px", cursor: "pointer" }}
                  onClick={handleOptionClick}
                />
                {showOptions && (
                  <div className="options-popup">
                    <ul>
                      <li onClick={handleShare}>
                        <i class="fa fa-share-square" aria-hidden="true"
                          style={{ marginRight: "7px", marginLeft: "4px" }}></i>
                        Share
                      </li>
                      <li onClick={copyIframeToClipboard}>
                        <i class="fa-solid fa-copy" aria-hidden="true"
                          style={{ marginRight: "7px", marginLeft: "4px" }}></i>
                        iframe
                      </li>
                      <li onClick={unPublishCallForData}>
                        <i
                          className="fa fa-eye-slash"
                          aria-hidden="true"
                          style={{ marginRight: "10px" }}
                        ></i>
                        Unpublish
                      </li>
                      <li onClick={deleteCallForData}>
                        <i
                          className="fa fa-trash"
                          aria-hidden="true"
                          style={{ marginRight: "13px", marginLeft: "4px" }}
                        ></i>
                        Delete
                      </li>
                      {/* <li onClick={showTrace}>
                        <i
                          className="fa fa-code-fork"
                          aria-hidden="true"
                          style={{ marginRight: "13px", marginLeft: "4px" }}
                        ></i>
                        Trace
                      </li> */}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>

          <p style={{ fontSize: "14px", lineHeight: "1" }}>
            A Call for Data will make a public posting on POIETO Data.
          </p>
          <div className="innerFourth">
            {!state.crudOperation ? (
              <div className="headingScreenFour">
                <p style={{ display: "flex", flexDirection: "row",color:"rgba(78, 78, 78, 0.7)" }}>
                  {state.dataTypes?.map((item, index) => {
                    return `${item.value}${index < state.dataTypes.length - 1 &&
                      item.value.length > 1
                      ? ","
                      : ""
                      } `;
                  })}
                </p>
                <p
                  style={{
                    color: "#4E4E4EB2",
                    fontWeight: "300",
                    fontSize: "32px",
                    margin: "25px 0px",
                     textTransform:"uppercase"
                  }}
                >
                  {state.DataCallTitle
                    ? state.DataCallTitle.length > 20
                      ? state.DataCallTitle.slice(0, 20) + "..."
                      : state.DataCallTitle
                    : "DATA CALL TITLE"}
                </p>
              </div>
            ) : (
              <div className="headingScreenFour">
                <p style={{ display: "flex", flexDirection: "row" ,color:"rgba(78, 78, 78, 0.7)" }}>
                  {parentStates?.dataTypes ? parentStates?.dataTypes : ""}
                </p>
                <p
                  style={{
                    color:"rgba(78, 78, 78, 0.7)",
                    fontWeight: "300",
                    fontSize: "32px",
                    margin: "25px 0px",
                    textTransform:"uppercase"
                  }}
                >
                  {parentStates?.DataCallTitle
                    ? parentStates?.DataCallTitle
                    : "DATA CALL TITLE"}
                </p>
              </div>
            )}

            <div className="projNameBox">
              <div className="d-flex align-items-center"  style={{ textTransform:"uppercase" ,color:"rgba(0, 0, 0, 0.7)"}}>
                {parentStates ? parentStates?.project_title : projectName ? projectName : "PROJECT"}
              </div>
            </div>
            <p style={{ color: "rgba(0, 0, 0, 0.7)", marginTop: "5px" }}>
              {state.title
                ? state.title.length > 50
                  ? state.title.slice(0, 50) + "..."
                  : state.title
                : state.crudOperation && parentStates?.title}
            </p>
            <p style={{ color: "rgba(0, 0, 0, 0.7)", lineHeight: "1.2" }}>
              {state.titleMessage
                ? state.titleMessage.length > 85
                  ? state.titleMessage.slice(0, 85) + " ..."
                  : state.titleMessage
                : state.crudOperation && parentStates.message.length > 85
                  ? parentStates.message.slice(0, 85) + " ..."
                  : parentStates.message && parentStates.message.length > 85
                    ? parentStates.message.slice(0, 85) + " ..." : parentStates.message}
            </p>
          </div>
          <div
            className="footer"
            style={{
              width: "100%",
              justifyContent: state.crudOperation ? "center" : "space-between",
            }}
          >
            <button
              onClick={handleBack}
              className="FooterBackbutton"
              style={{
                width: state.crudOperation ? "75%" : "48%",
                height: "50px",
              }}
            >
              Back to Edit
            </button>
            {!state.crudOperation && (
              <button
                onClick={saveCallForData}
                className="footerButton"
                style={{ width: "48%", height: "50px",background:"rgba(32, 159, 140, 1)",color:"rgba(255, 255, 255, 0.7)" }}
              >
                Publish
              </button>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  // Function thats change the screens components according to the Screen Count
  const switchScreens = () => {
    switch (screenCount) {
      case 1:
        return initialScreen();
      case 2:
        return flowSelectionScreen();
      case 3:
        return screenOne();
      case 4:
        return screenTwo();
      case 5:
        return screenThree();
      case 6:
        return screenFour();
      default:
        return initialScreen();
    }
  };

  const borderStyle = (cardNumber) => ({
    margin: '10px 0',
    width: "80%",
  });
  const getLabelStyle = (cardNumber) => ({
    color: (screenCount === cardNumber || screenCount === cardNumber || screenCount === cardNumber) ? '#000000' : '#4E4E4EB2'
  });
  return (
    <div
      className="outerMainContainer"
      style={{ width: screenWidth, zIndex: "1" }}
    >
      {screenCount === 6 ? (
        <>{screenFour()}</>
      ) : (
        <div
          className="innerCenterContainer"
          style={{ width: screenWidth / 1.7 }}
        >
          <div className="text-center " 
          style={{ margin: " 5px 15px",
          background:"rgba(32, 159, 140, 0.1)",
          borderRadius:"10px",width:"30px",height:"30px",textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center" }}>
            <i
              className="fa fa-times fa-lg"
              onClick={() => {
                closeModal(false);
              }}
              style={{ color: "rgba(0, 0, 0, 0.75)" }}
            ></i>
          </div>
          <div className="call-for-data-header">
            <p style={{ fontSize: "24px", color: "#4E4E4EB2", width: "100%" }}>
              Data Call Settings
            </p>
            {screenCount < 5 && screenCount > 2 ? (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <div className="step" style={{ ...borderStyle(3), textAlign: "center" }}>
                  <label className={`step-label ${screenCount === 3 ? 'active' : ''}`} style={{ ...getLabelStyle(3) }}>DATA</label>
                </div>
                <div className="step" style={{ ...borderStyle(4), textAlign: "center" }}>
                  <label className={`step-label ${screenCount === 4 ? 'active' : ''}`} style={{ ...getLabelStyle(4) }}>PROVENANCE</label>
                </div>
                <div className="step" style={{ ...borderStyle(5), textAlign: "center" }}>
                  <label className={`step-label ${screenCount === 5 ? 'active' : ''}`} style={{ ...getLabelStyle(5) }}>CONSENT & PERMISSION</label>
                </div>
              </div>
            ) : null}
            {switchScreens()}
          </div>




          <div className="footer" style={{ justifyContent: screenCount === 1 || screenCount === 3 || screenCount === 2 ? "center" : "space-evenly" }}>
            {screenCount === 1 || screenCount === 3 ? (
              <button
                onClick={changeScreen}
                style={{ width: "100%" }}
                className="footerButton"
              >
                Next
              </button>
            ) : screenCount === 2 ? (
              <button onClick={handleBack} style={{ width: "100%" }} className="FooterBackbutton">
                Back
              </button>
            ) : (
              <>
                <button onClick={handleBack}  style={{width: "48%"}} className="FooterBackbutton">
                  Back
                </button>
                <button
                  onClick={changeScreen}
                  style={{width: "48%"}}
                  className="footerButton"
                >
                  Next
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CallForData;
