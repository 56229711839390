import toast from "react-hot-toast";
import audioImage from "../Assets/Images/mp3_icon.png";
import mp4Image from '../Assets/Images/mp4_icon.png'
import wavImage from '../Assets/Images/wav_icon.png'
import aifImage from '../Assets/Images/aif_icon.png'
import movImage from '../Assets/Images/mov_icon.png'
import threeDImage from '../Assets/Images/3d_icon.png'
import pdfImage from '../Assets/Images/pdf_icon.png'
import txtImage from '../Assets/Images/TxtfFile.png'
export const classnames = (...args) => {
    return args.join(" ");
};



const errorMessages = {
    DataCallTitle: 'Please enter Data Call Title',
    title: 'Please enter title',
    titleMessage:'project description cannot be empty, please Write project description',
    author: 'Please enter author',
    howWasThisSourced: 'Please enter how was this sourced',
    fieldsData:"Please add alteast one question",
    dataTypes:"Please select atleast one data type",
    image: "Please upload an image",
    drawing: "Please Draw a Prompt",
    password:"Please enter Password",
    consent:"Please provide consent",
    terms:"Please accept terms & conditions"
  };
  
  const validateInput = (value, fieldName) => {
    if (!value) {
      toast.error(errorMessages[fieldName]);
      return false;
    }
    return true;
  };


  //upload data helper function to show uploaded data type image
  const getMediaImage = (item) => {
    if (item.name.endsWith('.obj') || item.name.endsWith('.STL') || item.name.endsWith('.usdz')) {
      return threeDImage;
    }
    switch (item?.type) {
      case "audio/mpeg":
        return audioImage;
      case "audio/aiff":
        return aifImage;
      case "audio/wav":
        return wavImage;
      case "video/mp4":
        return mp4Image;
      case "video/quicktime":
        return movImage;
      case "application/pdf":
        return pdfImage;
      case "text/plain":
        return txtImage;
      default:
        return URL.createObjectURL(item);
    }
  };

//upload data helper function to show dynamic label based on data type
  const getFileUploadLabel = (selectedValue) => {
    switch (selectedValue) {
      case "IMAGE (.PNG)":
      case "IMAGE (.JPG)":
      case "Image - Upload":
        return "Drop or choose a IMAGE file";
      case "AUDIO (.MP3)":
      case "Audio - Upload":
        return "Drop or choose a AUDIO file";
      case "Video - Upload":
        return "Drop or choose a VIDEO file";
      case "3D - Upload":
        return "Drop or choose a 3D file";
        case "TEXT - Upload":
          return "Drop or choose a TEXT file";
      default:
        return "Drop or choose a file";
    }
  };

  //upload data helper function to accept required dataType
  const acceptedFileTypes = {
    "IMAGE (.PNG)": ".png,.heic,.svg,.jpeg,.jpg",
    "Image - Upload": ".png,.heic,.svg,.jpeg,.jpg",
    "IMAGE (.JPG)": ".jpg,.jpeg,.heic,.svg,.png",
    "AUDIO (.MP3)": ".mp3,.wav,.aif",
    "Audio - Upload": ".mp3,.wav,.aif",
    "Video - Upload": ".mp4,.mov",
    "3D - Upload": ".obj,.stl,.usdz",
    "TEXT - Upload": ".txt,.pdf",
};

const defaultFileType = ".png";
const getAcceptedFiles = (selectedValue) => {
  return acceptedFileTypes[selectedValue] || defaultFileType;
};

const fileTypeIcons = {
  mp3: audioImage,
  wav: wavImage,
  aif: aifImage,
  mp4: mp4Image,
  mov: movImage,
  txt: txtImage,
  pdf: pdfImage,
  obj: threeDImage,
  stl:threeDImage,
  usdz:threeDImage
};

const getFileIcon = (filePath) => {
  if (!filePath) return null;

  // Decode the URL to handle encoded characters like '%20' for spaces
  const decodedPath = decodeURIComponent(filePath);

  // Extract the file extension
  const extensionMatch = decodedPath.match(/\.([a-zA-Z0-9]+)(?=\?|$)/);
  const extension = extensionMatch ? extensionMatch[1].toLowerCase() : null;

  // Return the appropriate icon or the filePath itself if no match is found
  return fileTypeIcons[extension] || filePath;
};


const embedSvg = (
  <svg width="222" height="194" viewBox="0 0 222 194" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M163.677 135.431C158.371 135.431 153.573 133.279 150.095 129.802C146.618 126.324 144.466 121.526 144.466 116.22C144.466 110.914 142.314 106.106 138.836 102.629C135.359 99.1604 130.56 97.0084 125.254 97.0084C135.862 97.0084 144.466 88.4047 144.466 77.7968C144.466 72.4905 146.618 67.683 150.095 64.2056C153.573 60.7373 158.371 58.5853 163.677 58.5853C168.983 58.5853 173.782 60.7373 177.259 64.2056C180.737 67.683 182.889 72.4905 182.889 77.7968C182.889 88.4047 174.285 97.0084 163.677 97.0084C168.983 97.0084 173.782 99.1604 177.259 102.629C180.737 106.106 182.889 110.914 182.889 116.22C182.889 126.828 174.285 135.431 163.677 135.431Z" fill="black" stroke="black" stroke-miterlimit="10"/>
<path d="M58.6002 135.431C63.9065 135.431 68.7048 133.279 72.1822 129.802C75.6597 126.324 77.8118 121.526 77.8118 116.22C77.8118 110.914 79.9638 106.106 83.4413 102.629C86.9188 99.1604 91.717 97.0084 97.0233 97.0084C86.4154 97.0084 77.8118 88.4047 77.8118 77.7968C77.8118 72.4905 75.6597 67.683 72.1822 64.2056C68.7048 60.7373 63.9065 58.5853 58.6002 58.5853C53.294 58.5853 48.4957 60.7373 45.0182 64.2056C41.5407 67.683 39.3887 72.4905 39.3887 77.7968C39.3887 88.4047 47.9923 97.0084 58.6002 97.0084C53.294 97.0084 48.4957 99.1604 45.0182 102.629C41.5407 106.106 39.3887 110.914 39.3887 116.22C39.3887 126.828 47.9923 135.431 58.6002 135.431Z" fill="black" stroke="black" stroke-miterlimit="10"/>
<path d="M72.6382 135.227C72.6382 129.921 74.7903 125.122 78.2677 121.645C81.7452 118.168 86.5435 116.015 91.8497 116.015C97.156 116.015 101.964 113.863 105.441 110.386C108.909 106.908 111.061 102.11 111.061 96.8039C111.061 107.412 119.665 116.015 130.273 116.015C135.579 116.015 140.387 118.168 143.864 121.645C147.332 125.122 149.484 129.921 149.484 135.227C149.484 140.533 147.332 145.332 143.864 148.809C140.387 152.287 135.579 154.439 130.273 154.439C119.665 154.439 111.061 145.835 111.061 135.227C111.061 140.533 108.909 145.332 105.441 148.809C101.963 152.287 97.156 154.439 91.8497 154.439C81.2418 154.439 72.6382 145.835 72.6382 135.227Z" fill="black" stroke="black" stroke-miterlimit="10"/>
<path d="M149.485 58.4304C149.485 63.7366 147.333 68.5349 143.855 72.0124C140.378 75.4898 135.58 77.6419 130.273 77.6419C124.967 77.6419 120.16 79.794 116.682 83.2714C113.214 86.7489 111.062 91.5472 111.062 96.8534C111.062 86.2455 102.458 77.6419 91.8502 77.6419C86.544 77.6419 81.7365 75.4898 78.259 72.0124C74.7907 68.5349 72.6387 63.7366 72.6387 58.4304C72.6387 53.1241 74.7907 48.3258 78.259 44.8483C81.7365 41.3709 86.544 39.2188 91.8502 39.2188C102.458 39.2188 111.062 47.8225 111.062 58.4304C111.062 53.1241 113.214 48.3258 116.682 44.8483C120.16 41.3709 124.967 39.2188 130.273 39.2188C140.881 39.2188 149.485 47.8225 149.485 58.4304Z" fill="black" stroke="black" stroke-miterlimit="10"/>
<path d="M187.875 20.2116C187.875 25.5178 185.723 30.3161 182.246 33.7936C178.768 37.271 173.97 39.4231 168.664 39.4231C163.358 39.4231 158.55 41.5752 155.073 45.0526C151.604 48.5301 149.452 53.3284 149.452 58.6346C149.452 48.0267 140.849 39.4231 130.241 39.4231C124.935 39.4231 120.127 37.271 116.65 33.7936C113.181 30.3161 111.029 25.5178 111.029 20.2116C111.029 14.9053 113.181 10.107 116.65 6.62954C120.127 3.15206 124.935 1 130.241 1C140.849 1 149.452 9.60365 149.452 20.2116C149.452 14.9053 151.604 10.107 155.073 6.62954C158.55 3.15206 163.358 1 168.664 1C179.272 1 187.875 9.60365 187.875 20.2116Z" fill="black" stroke="black" stroke-miterlimit="10"/>
<path d="M187.875 173.788C187.875 168.482 185.723 163.684 182.246 160.206C178.768 156.729 173.97 154.577 168.664 154.577C163.358 154.577 158.55 152.425 155.073 148.947C151.604 145.47 149.452 140.672 149.452 135.365C149.452 145.973 140.849 154.577 130.241 154.577C124.935 154.577 120.127 156.729 116.65 160.206C113.181 163.684 111.029 168.482 111.029 173.788C111.029 179.095 113.181 183.893 116.65 187.37C120.127 190.848 124.935 193 130.241 193C140.849 193 149.452 184.396 149.452 173.788C149.452 179.095 151.604 183.893 155.073 187.37C158.55 190.848 163.358 193 168.664 193C179.272 193 187.875 184.396 187.875 173.788Z" fill="black" stroke="black" stroke-miterlimit="10"/>
<path d="M202.068 20.1951C196.761 20.1951 191.963 22.3471 188.486 25.8246C185.008 29.3021 182.856 34.1003 182.856 39.4066C182.856 44.7129 180.704 49.5204 177.227 52.9979C173.749 56.4661 168.951 58.6182 163.645 58.6182C174.253 58.6182 182.856 67.2218 182.856 77.8297C182.856 83.136 185.008 87.9435 188.486 91.421C191.963 94.8892 196.761 97.0413 202.068 97.0413C207.374 97.0413 212.172 94.8892 215.65 91.421C219.127 87.9435 221.279 83.136 221.279 77.8297C221.279 67.2218 212.676 58.6182 202.068 58.6182C207.374 58.6182 212.172 56.4661 215.65 52.9979C219.127 49.5204 221.279 44.7129 221.279 39.4066C221.279 28.7987 212.676 20.1951 202.068 20.1951Z" fill="black" stroke="black" stroke-miterlimit="10"/>
<path d="M20.2116 20.1951C25.5178 20.1951 30.3161 22.3471 33.7936 25.8246C37.271 29.3021 39.4231 34.1003 39.4231 39.4066C39.4231 44.7129 41.5752 49.5204 45.0526 52.9979C48.5301 56.4661 53.3284 58.6182 58.6346 58.6182C48.0267 58.6182 39.4231 67.2218 39.4231 77.8297C39.4231 83.136 37.271 87.9435 33.7936 91.421C30.3161 94.8892 25.5178 97.0413 20.2116 97.0413C14.9053 97.0413 10.107 94.8892 6.62954 91.421C3.15206 87.9435 1 83.136 1 77.8297C1 67.2218 9.60365 58.6182 20.2116 58.6182C14.9053 58.6182 10.107 56.4661 6.62954 52.9979C3.15207 49.5204 1 44.7129 1 39.4066C1 28.7987 9.60365 20.1951 20.2116 20.1951Z" fill="black" stroke="black" stroke-miterlimit="10"/>
<path d="M111.094 20.2116C111.094 25.5178 108.942 30.3161 105.465 33.7936C101.987 37.271 97.189 39.4231 91.8827 39.4231C86.5764 39.4231 81.7689 41.5752 78.2915 45.0526C74.8232 48.5301 72.6711 53.3284 72.6711 58.6346C72.6711 48.0267 64.0675 39.4231 53.4596 39.4231C48.1533 39.4231 43.3458 37.271 39.8684 33.7936C36.4001 30.3161 34.248 25.5178 34.248 20.2116C34.248 14.9053 36.4001 10.107 39.8684 6.62954C43.3458 3.15206 48.1533 1 53.4596 1C64.0675 1 72.6711 9.60365 72.6711 20.2116C72.6711 14.9053 74.8232 10.107 78.2915 6.62954C81.7689 3.15206 86.5764 1 91.8827 1C102.491 1 111.094 9.60365 111.094 20.2116Z" fill="black" stroke="black" stroke-miterlimit="10"/>
<path d="M111.094 173.788C111.094 168.482 108.942 163.684 105.465 160.206C101.987 156.729 97.189 154.577 91.8827 154.577C86.5764 154.577 81.7689 152.425 78.2915 148.947C74.8232 145.47 72.6711 140.672 72.6711 135.365C72.6711 145.973 64.0675 154.577 53.4596 154.577C48.1533 154.577 43.3458 156.729 39.8684 160.206C36.4001 163.684 34.248 168.482 34.248 173.788C34.248 179.095 36.4001 183.893 39.8684 187.37C43.3458 190.848 48.1533 193 53.4596 193C64.0675 193 72.6711 184.396 72.6711 173.788C72.6711 179.095 74.8232 183.893 78.2915 187.37C81.7689 190.848 86.5764 193 91.8827 193C102.491 193 111.094 184.396 111.094 173.788Z" fill="black" stroke="black" stroke-miterlimit="10"/>
<path d="M202.068 96.9754C196.761 96.9754 191.963 99.1274 188.486 102.605C185.008 106.082 182.856 110.881 182.856 116.187C182.856 121.493 180.704 126.301 177.227 129.778C173.749 133.246 168.951 135.398 163.645 135.398C174.253 135.398 182.856 144.002 182.856 154.61C182.856 159.916 185.008 164.724 188.486 168.201C191.963 171.669 196.761 173.822 202.068 173.822C207.374 173.822 212.172 171.669 215.65 168.201C219.127 164.724 221.279 159.916 221.279 154.61C221.279 144.002 212.676 135.398 202.068 135.398C207.374 135.398 212.172 133.246 215.65 129.778C219.127 126.301 221.279 121.493 221.279 116.187C221.279 105.579 212.676 96.9753 202.068 96.9754Z" fill="black" stroke="black" stroke-miterlimit="10"/>
<path d="M20.2116 96.9754C25.5178 96.9754 30.3161 99.1274 33.7936 102.605C37.271 106.082 39.4231 110.881 39.4231 116.187C39.4231 121.493 41.5752 126.301 45.0526 129.778C48.5301 133.246 53.3284 135.398 58.6346 135.398C48.0267 135.398 39.4231 144.002 39.4231 154.61C39.4231 159.916 37.271 164.724 33.7936 168.201C30.3161 171.669 25.5178 173.822 20.2116 173.822C14.9053 173.822 10.107 171.669 6.62954 168.201C3.15206 164.724 1 159.916 1 154.61C1 144.002 9.60365 135.398 20.2116 135.398C14.9053 135.398 10.107 133.246 6.62954 129.778C3.15207 126.301 1 121.493 1 116.187C1 105.579 9.60365 96.9753 20.2116 96.9754Z" fill="black" stroke="black" stroke-miterlimit="10"/>
</svg>

)




  
  export { embedSvg,validateInput,getMediaImage,getFileUploadLabel ,getAcceptedFiles,getFileIcon};
