import React, { useState } from "react";
import services from "../../API_SERVICES/services";

const DalleGeneration = () => {
    const [imagesGenerated, setImagesGenerated] = useState([null, null, null, null, null]); // Array for images
    const [loaders, setLoaders] = useState([false, false, false, false, false]); // Array for loaders
    const [prompt, setPrompt] = useState("");
    const [buttonText, setButtonText] = useState("Generate"); // Button text

    // Handle prompt change
    const promptChange = (event) => {
        setPrompt(event.target.value);
    };

    // Function to sequentially generate images
    const generateSequentialImages = async (transformedPrompt) => {
        setButtonText("Generating Images...");
        for (let i = 0; i < 5; i++) {
            // Activate loader for the current index
            setLoaders((prev) => {
                const updatedLoaders = [...prev];
                updatedLoaders[i] = true;
                return updatedLoaders;
            });

            let apiname = "dalle-generate-image";
            let data = { prompt: transformedPrompt };

            try {
                // setTimeout();
                const response = await services.post(apiname, data);
                const newImage = "data:image/png;base64, " + response?.data?.data?.base64;

                // Update the image for the current index
                setImagesGenerated((prev) => {
                    const updatedImages = [...prev];
                    updatedImages[i] = newImage;
                    return updatedImages;
                });

                // Deactivate loader for the current index
                setLoaders((prev) => {
                    const updatedLoaders = [...prev];
                    updatedLoaders[i] = false;
                    return updatedLoaders;
                });
            } catch (error) {
                // Deactivate loader if there's an error
                setLoaders((prev) => {
                    const updatedLoaders = [...prev];
                    updatedLoaders[i] = false;
                    return updatedLoaders;
                });
            }
        }
        setButtonText("Generate"); // Reset button text after all images are generated
    };

    // Main function to handle prompt transformation and sequential generation
    const generateImages = async () => {
        setButtonText("Transforming Prompt...");
        let apiname = "transform-prompt";
        let data = { prompt: prompt };

        try {
            const response = await services.post(apiname, data);
            const transformedPrompt = response.data.data.prompt;

            // Call the sequential generation function
            await generateSequentialImages(transformedPrompt);
        } catch (error) {
            setButtonText("Generate"); // Reset button text if an error occurs
        }
    };

    // Download an image
    const downloadImage = (image) => {
        const link = document.createElement("a");
        link.href = image;
        link.download = "generated_image.png";
        link.click();
    };
    return (
        <>
            <p className="chatgptcontainer-header ps-3">Animating kindness</p>
            <div className="dalle-container-kindness p-4">
                <h4 className="text-center" style={{ color: " #0000008A" }}>
                    Enter a prompt:
                </h4>
                <input
                    value={prompt}
                    onChange={promptChange}
                    className="dalle-image-input mt-2"
                    type="text"
                />
                <div className="dalle-btn-wrapper">
                    <button
                        className="dalle-generate-btn-kindness"
                        onClick={generateImages}
                        disabled = {buttonText === "Transforming Prompt..." || buttonText === "Generating Images..."}
                    >
                        {buttonText}
                    </button>
                </div>
                <div className="dalle-image-grid mt-3">
                    {imagesGenerated.map((image, index) => (
                        <div key={index} className="image-container">
                            {loaders[index] ? (
                                <div className="dalle-loader-kindness">
                                    <div className="dalle-circle-kindness"></div>
                                    <div className="dalle-circle-kindness"></div>
                                    <p className="text-center">Generating image {index + 1}...</p>
                                </div>
                            ) : (
                                image && (
                                    <>
                                        <img
                                            className="dalle-generated-image"
                                            src={image}
                                            alt={`Generated ${index}`}
                                        />
                                        <div className="dalle-btn-wrapper mt-2">
                                            <button
                                                onClick={() => downloadImage(image)}
                                                className="dalle-generate-btn-kindness"
                                            >
                                                Download
                                            </button>
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <style jsx="true">{`
                .dalle-image-grid {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr); /* 3 images per row */
                    gap: 16px; /* Space between images */
                }
                .image-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .dalle-generated-image {
                    width: 100%;
                    max-width: 250px; /* Fixed image size */
                    height: auto;
                    border-radius: 8px;
                }
                .dalle-loader-kindness {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            `}</style>
        </>
    );
};

export default DalleGeneration;
