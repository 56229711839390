import React from "react";
import "./CustomUploadData.css";
import { CustomInputField } from "./CustomInputField";
import DrawingArea from "./Canvas";
import services from "../../API_SERVICES/services";
import { isAuth } from "../../Storage(Methods)/storage";
import { validateInput,getMediaImage,getAcceptedFiles } from "../../utils/general";
import Ellipse1 from "../../Assets/Images/redEclipse1.png"
import Ellipse2 from "../../Assets/Images/redEclipse2.png"
import AudioRecorder from "./AudioRecorder";
import LocationComponent from "./LocationComponent";
import heic2any from "heic2any";
import toast from "react-hot-toast";
import Dummy from '../../Assets/Images/dummyfileforTextUploadonly.png';

const CustomUploadData = ({
  completeUploadData,
  parentStates,
  pId,
  isLoading,
  showMobileUpload,
}) => {
  const [state, setState] = React.useState({
    image: "",
    renderImage: "",
    author: "",
    ids: [],
    whereDidThisComeFrom: "",
    howWasThisSourced: "",
    email: "",
    donationOrPaid: "",
    permissionOne: "",
    permissionTwo: "",
    permissionThree: "",
    dataFields: [],
    optionalFields: [],
    isSubmitting: false,
    locationData:[],
    termsAndCondition:''
  });
  
 
  const [isDataFieldsUpdated, setIsDataFieldsUpdated] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [dummyFile,setDummyFile] = React.useState(null)
  const handleAddStateData = () => {
    window.addEventListener("resize", handleResize);
    setState({
      ...state,
      optionalFields: parentStates?.optional_question_data
        ? parentStates?.optional_question_data
        : [],
      dataFields: parentStates?.call_for_data_fields
        ? parentStates?.call_for_data_fields
        : [],
      dataTypes: parentStates?.dataTypes ? parentStates?.dataTypes : [],
      author: parentStates?.core_question_author_name,
      howWasThisSourced: parentStates?.core_question_how_this_sourced,
      whereDidThisComeFrom: parentStates?.core_question_where_did_this_come,
    });
    return () => window.removeEventListener("resize", handleResize);
  }
   
  const [svgFile, setSvgFile] = React.useState(null);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const [currentCard, setCurrentCard] = React.useState(1);
  const [showCanva, setShowCanva] = React.useState(false);

  React.useEffect(() => {
    const convertImageToFile = async () => {
      const response = await fetch(Dummy);
      const blob = await response.blob();
      const file = new File([blob], 'dummyfileforTextUploadonly.png', { type: blob.type });
      setDummyFile(file);
    };
    convertImageToFile();
  }, []); 
  React.useEffect(() => {
    if (screenWidth <= 768) {
      setIsMobile(true);
    }
    handleAddStateData();
  }, [parentStates, screenWidth]);


  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };
  const handleCanva = (index) => {
    setShowCanva(!showCanva);
    if (!showCanva) {
      updateSvgDataFields(index, null); // Update the answer property to null
    }
    setSvgFile(null);
    setState((prevState) => ({ ...prevState, image: "" }));
  };

  const handleSaveAudio = (fileData, index) => {
    const { file } = fileData;
    updateAudioDataFields(index, file); 
  };
 

  const updateAudioDataFields = (index, audioFile) => {
    const tempArray = [...state.dataFields];
  
    if (tempArray[index].answer instanceof File) {
      // If the audio file is already stored in the state, update it
      tempArray[index] = {
        ...tempArray[index],
        answer: audioFile,
      };
    } else {
      // If the audio file is not stored in the state, add it
      tempArray[index] = {
        ...tempArray[index],
        answer: audioFile,
      };
    }
  
    setState({ ...state, dataFields: tempArray });
  };

  const handleDeleteAudio = (index) => {
    setState((prevState) => {
      const updatedAudioFiles = prevState?.audioFiles?.filter((_, i) => i !== index);
      return { ...prevState, audioFiles: updatedAudioFiles };
    });
  };

  const hasAnswerWithExtension = state?.image || state?.dataFields?.some((field) => {
    return field.answer && (
      field.answer.type === 'image/png' || 
      field.answer.type === 'image/jpg' || 
      field.answer.type === 'image/jpeg' || 
      field.answer.type === "audio/mpeg" || 
      field.answer.type === 'audio/aiff' || 
      field.answer.type === 'audio/wav' || 
      field.answer.type === 'image/svg+xml' ||
      field.answer.type === "video/mp4" ||
      field.answer.type === "video/quicktime" ||
      field.answer.type === "application/pdf" ||
      field.answer.type === "text/plain" ||
      (field.answer.name && (
          field.answer.name.endsWith('.obj') || 
          field.answer.name.endsWith('.stl') || 
          field.answer.name.endsWith('.usdz') || 
          field.answer.name.endsWith('.glTF')
      ))
    );
});
 

const hasAudioOrImage = state?.dataFields?.some((field) => {
  return [
    'AUDIO (.MP3)',
    'IMAGE (.PNG)',
    'IMAGE (.JPG)',
    "Image - Upload",
    "Audio - Upload",
    "Audio - Record",
    "Video - Upload",
    "3D - Upload",
    "TEXT - Upload"
  ].includes(field.selectedValue);
});

const getFirstMatchingMediaURL = (dataFields) => {
  for (const field of dataFields) {
      // Check if the item name ends with specific 3D model file extensions
      if (field?.answer?.name?.endsWith('.obj') || 
          field?.answer?.name?.endsWith('.stl') || 
          field?.answer?.name?.endsWith('.usdz') || 
          field?.answer?.name?.endsWith('.glTF')) {
          return field.answer; // Return the answer if it matches the 3D file types
      }

      // Check for audio file types
      if (field?.answer?.type === "audio/mpeg" || 
          field?.answer?.type === 'audio/aiff' ||  
          field?.answer?.type === 'audio/wav') {
          return field.answer;
      } 
      
      // Check for image, video, PDF, and plain text file types
      else if (
          field?.answer?.type === "image/png" ||
          field?.answer?.type === "image/jpg" ||
          field?.answer?.type === "image/svg+xml" ||
          field?.answer?.type === "image/jpeg" ||
          field?.answer?.type === "video/mp4" ||
          field?.answer?.type === "video/quicktime" ||
          field?.answer?.type === "application/pdf" ||
          field?.answer?.type === "text/plain"
      ) {
          return field?.answer;
      }
  }
  return state?.renderImage;
};

// Get the first matching media URL from the dataFields array
const mediaURL = getFirstMatchingMediaURL(state?.dataFields);
  
 


  const labelStyles = {
    margin: "15px 0 15px 0",
    fontSize: isMobile ? "14px" : "16px",
    color:isMobile ? "rgba(0, 0, 0, 1)" : ''
  };

  const labelConfirm = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: isMobile ? "12px" : "14px",
    marginBottom: "10px",
  };
  const labelConfirm1 = {
    display: "flex",
    justifyContent: "center",
    alignItems:"center",
    fontSize: isMobile ? "12px" : "14px",
    marginBottom: "10px",
    marginTop : isMobile ? "10%" : "0",
color:"rgba(32, 159, 140, 1)"
  };

  const UploadedimageStyle = {
    width: isMobile ? "50px" : "100px",
    height: isMobile ? "50px" : "100px",
  };


  const handleNext = () => {
    if (mediaURL) {
      // Perform validation only if mediaURL is true
      if ((!isMobile && currentCard === 2) || (isMobile && currentCard === 3)) {
        if (
          !validateInput(state.author, "author") ||
          !validateInput(state.howWasThisSourced, "howWasThisSourced")
        ) {
          return; // Return if validation fails for author or howWasThisSourced
        }
      }
    }

    // Check if currentCard is 1 and validate state.dataFields
    if ((!isMobile && currentCard === 1) || (isMobile && currentCard === 2)) {
      const hasAnswer = state.dataFields.some(field => {
        // Check if the answer is a string and not empty
        if (typeof field?.answer === "string" && field?.answer.trim() !== "") {
          return true;
        }
        // Check if the answer is a File
        if (field?.answer instanceof File) {
          return true;
        }
        return false;
      });
    
      if (!hasAnswer && !state.image) {
        toast.error("Please provide at least one answer");
        return;
      }

    }
    if(currentCard == 1 && state.dataFields.length === 0 && !state.image){
      toast.error("Please provide at least one answer");
      return;
    }
    setCurrentCard(currentCard + 1);
  };

  const handleBack = () => {
    setCurrentCard(currentCard - 1);
  };

  const close = () => {
    window.top.location.href = 'https://www.poieto.com/beta';
}

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setState((prevState) => ({
      ...prevState,
      image: file,
    }));
    const reader = new FileReader();
    reader.onload = () => {
      setState((prevState) => ({
        ...prevState,
        renderImage: reader.result,
      }));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () =>{
    setState((prevState) => ({
      ...prevState,
      image: null,
      renderImage:null
    }));
  }

  React.useEffect(() => {
    if (svgFile) {
      setState((prevState) => ({
        ...prevState,
        image: svgFile,
      }));
    }
  }, [svgFile]);

  const setStateData = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

 
  const updateDataFields = async (event, index, inputName) => {
    const isFileType = event.target.type === "file";
    const tempArray = [...state.dataFields];
  
    if (isFileType) {
      const file = event.target.files[0];
      if (file) {
        const fileName = file.name.toLowerCase();
        const fileType = file.type || (fileName.endsWith(".heic") ? "image/heic" : ""); // Fallback for empty type
        const acceptedFileTypes = getAcceptedFiles(state.dataFields[index].selectedValue).split(",");
      
        // Check if the uploaded file type is accepted
        if (!acceptedFileTypes.some(type => fileType.includes(type.trim()) || fileName.endsWith(type.trim()))) {
          // Show error toast for invalid file type
          toast.error(`Invalid file type. Please upload files of type: ${acceptedFileTypes.join(", ")}`);
          return;
        }
        if (fileType === "image/heic") {
          try {
            const convertedBlob = await heic2any({
              blob: file,
              toType: "image/png",
            });
  
            const convertedFile = new File([convertedBlob], `${file.name}.png`, {
              type: "image/png",
            });
  
            tempArray[index] = {
              ...tempArray[index],
              answer: convertedFile,
            };
          } catch (error) {
            console.error("Error converting HEIC file:", error);
            return;
          }
        } else {
          // For other file types, directly update the state
          tempArray[index] = {
            ...tempArray[index],
            answer: file,
          };
        }
        setState({ ...state, dataFields: tempArray });
      }
    } else {
      // Handle non-file input
      const inputValue = event.target.value;
      const tempObj = {
        question: state.dataFields[index].question,
        answer: inputValue,
        selectedValue: state.dataFields[index].selectedValue,
      };
      tempArray[index] = tempObj;
      setState({ ...state, dataFields: tempArray });
    }
  };

  const updateSvgDataFields = (index, svgFile) => {
    const tempArray = [...state.dataFields];
  
    tempArray[index] = {
      ...tempArray[index],
      answer: svgFile, // Update the answer property with the svgFile
    };
  
    setState({ ...state, dataFields: tempArray });
  };

  const updateOptionalfields = (event, index) => {
    var optArray = state.optionalFields;
    var optObj = {
      question: state.optionalFields[index].question,
      value: state.optionalFields[index].question,
    };
    optArray[index] = optObj;
    setState({ ...state, optionalFields: optArray });
  };

  const setPermissions = (event) => {
    const { name, checked, value } = event.target;

    setState((prevState) => ({
      ...prevState,
      [name]: checked ? value : "",
    }));
  };

  const submitForm = () => {
    setState({ ...state, isSubmitting: true });
    const filesToUpload = state.dataFields.filter((field) => field.answer instanceof File);
    if (filesToUpload.length === 0) {
      if (state.dataFields.length > 0) {
          // Update state with Base64 image and proceed
          setState((prevState) => {
            const newState = { ...prevState, image: dummyFile, author: "N/A" };
            completeUploadData(newState);
            return newState;
          });
        }
      else {
        completeUploadData(state);
      }
      return;
    }
    const promises = filesToUpload.map((field, index) => {
      return imageUploadS3(field.answer, index);
    });
    Promise.all(promises).then((responses) => {
      const updatedDataFields = state.dataFields.map((field, index) => {
        if (field.answer instanceof File) {
          return {
            ...field,
            answer: responses[index]?.data?.data?.video,
          };
        }
        return field;
      });
      const fileIds = responses.map((response) => ({ id: response.data.data.dcId }));
      setState((prevState) => ({
        ...prevState,
        dataFields: updatedDataFields,
        ids: [...prevState.ids, ...fileIds]
      }));
      setIsDataFieldsUpdated(true);
    });
  };

  React.useEffect(() => {
    if ( isDataFieldsUpdated && state.dataFields.length > 0 && state.ids.length > 0) {
      completeUploadData(state);
      setIsDataFieldsUpdated(false);
    }
  }, [state.dataFields, state.ids, isDataFieldsUpdated]);

  const imageUploadS3 = (file, index) => {
    const apiname = "data_upload";
    var formData = new FormData();
    formData.append("pId", pId);
    formData.append("dataUpload", file);
    formData.append("user_id", "N/A");
    formData.append("contributor", "UNKNOWN");
    return services.post(apiname, formData).then((response) => {
      return response;
    });
  };

  const handleLocationDataUpdate =(data)=>{
setState({...state,locationData:data})
}

  const renderMessage = () => {
    if (!parentStates?.message) {
      return null;
    }

    if (parentStates.message.length <= charLimit || isExpanded) {
      return parentStates.message.includes('\n') ? (
        parentStates.message.split('\n').map((paragraph, index) => (
          <p
            key={index}
            style={{
              fontSize: '16px',
              textAlign: 'justify',
              marginTop: '5px',
              fontWeight: '400',
            }}
          >
            {paragraph}
          </p>
        ))
      ) : (
        <p
          style={{
            fontSize: '16px',
            textAlign: 'justify',
            marginTop: '5px',
            fontWeight: '400',
          }}
        >
          {parentStates.message}
        </p>
      );
    } else {
      return (
        <>
          <p
            style={{
              fontSize: '16px',
              textAlign: 'justify',
              marginTop: '5px',
              fontWeight: '400',
            }}
          >
            {parentStates.message.substring(0, charLimit)}...
          </p>
          <span
            onClick={toggleReadMore}
            style={{
              color: '#000000',
              cursor: 'pointer',
              fontSize: '16px',
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "400"
            }}
          >
            Read more
          </span>
        </>
      );
    }
  };

  const infoContainer = () => (
    <div className="custom-cfd-info-container" style={{height:"100%"}}>
    <div className="d-flex align-items-center greenBox">
      {parentStates.projName.toUpperCase()}  
    </div>
    <label style={{ ...labelStyle, fontSize: "32px", color: "#000000", fontWeight: "400" }}>
      {parentStates?.DataCallTitle?.toUpperCase()}
    </label>
    <label style={labelStyle}>THE CALL</label>
    <label style={{ ...labelStyle, textAlign: "justify", fontSize: "20px" }}>
      {parentStates?.title}
    </label>
    <div className="custom-cfd-side-Proj">
      <label style={{ marginTop: "20px", fontSize: "14px", color: "#000000" }}>
        PROJECT DESCRIPTION
      </label>
      {renderMessage()}
      {isExpanded && parentStates.message.length > charLimit && (
        <span
          onClick={toggleReadMore}
          style={{
            color: '#000000',
            cursor: 'pointer',
            fontSize: '16px',
            display: 'block',
            marginTop: '10px',
            fontFamily: "DM Sans"
          }}
        >
          Show less
        </span>
      )}
      <br></br>
      <br></br>
      <label style={{ marginBottom: "10px", fontSize: "14px",color:"#000000"}}>
          DATA SETTINGS
          </label>
          <div className="Data-Privacy-container">
            <label style={{...labelStyle,margin:"2px"}}>Privacy</label>
            <label style={{color:'#000000'}}>{parentStates?.privacy}</label>
            <label style={{color:"rgba(0, 0, 0, 0.7)"}}>
  {parentStates?.privacy === "PRIVATE" 
    ? "Project is listed privately in our project" 
    : <>Project is listed publicly in our project database <br/> and others can request to join.</>}
</label>
          </div>
          {parentStates?.contributionTypes?.length > 0 &&
           <label style={{...labelStyle,margin:"2px"}}>Contribution Type</label>}
         
          {parentStates?.contributionTypes && parentStates?.contributionTypes.map((type, index) => (
  <div className="Data-Privacy-container" key={index} style={{margin:"0"}}>
    <label style={{color:'#000000'}}>{type}</label>
  </div>
))}
    </div>
  </div>
  )

  const removeAnswer = (index) => {
    const tempArray = [...state.dataFields];
  
    tempArray[index] = {
      ...tempArray[index],
      answer: null, 
    };

    setState({ ...state, dataFields: tempArray });
  
  };
  const initialCard = () => (
    <React.Fragment>
      <div className="custom-cfd-questions">
        {state.dataFields.length > 0 ? (
          state.dataFields.map((item, index) => (
            <div key={index.toString()}>
            {item.selectedValue === "IMAGE (.PNG)" || 
            item.selectedValue === "Image - Upload" ||
            item.selectedValue === "IMAGE (.JPG)" ||
            item.selectedValue === "Audio - Upload" ||  
            item.selectedValue === "AUDIO (.MP3)" || 
            item.selectedValue === 'Video - Upload' ||
             item.selectedValue === '3D - Upload' ||
             item.selectedValue === 'TEXT - Upload'  ? (
                <div style={{ display: "flex", flexDirection: "column", margin: isMobile ? "0px 0 10px 0" : "20px 0 20px 0" }}>
                  <label style={{ margin: "5px 0 10px 0", color: "#000000" }}>
                    {item.question}
                  </label>
                  <div className="custom-cfd-upload" style={{ height: isMobile ? "200px" : "220px", border:isMobile ? "1px solid rgba(0, 0, 0, 0.4)" : "1px solid #000000" }}>
                    <label htmlFor={`UploadImage${index}`} id="prompt">
                      {item.answer ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                           src={getMediaImage(item?.answer)}
                            alt="Uploaded"
                            style={{ width: "100px", height: "100px"}}
                          />
                           <i
            className="far fa-times-circle fa-lg image-remove-icon"
            onClick={() => {
              removeAnswer(index);
            }}
            style={{ color: "grey" ,cursor:"pointer"}}
          ></i>
                        </div>
                      ) : (
                        <div className="custom-cfd-img-input">
                          {!isMobile &&
                          <>
                            <img src={Ellipse2} alt="Bottom Left" className="circle-image2 circle-bottom-left" />
                            <img src={Ellipse1} alt="Top Right" className="circle-image1 circle-top-right" /></>
                          }
                        
                          <h5
                            style={{
                              fontSize: "50px",
                              color: "rgba(32, 159, 140, 1)",
                              cursor: "pointer",
                              textAlign: "center",
                              margin: "0"
                            }}
                          >
                            +
                          </h5>
                        </div>
                      )}
                    </label>
                    <input
                      type="file"
                      id={`UploadImage${index}`}
                      name={`UploadImage${index}`}
                      label={item.question}
                      style={{ display: "none" }}
                      accept={getAcceptedFiles(item.selectedValue)}
                      onChange={(event) =>
                        updateDataFields(event, index, `UploadImage${index}`)
                      }
                    />
                  </div>
                </div>
              ) : item.selectedValue === "IMAGE (.SVG)" ||
                item.selectedValue === "DRAW" ||  item.selectedValue === "Image - Draw" ?  (
                <>
                  <label style={{ margin: "5px 0 10px 0", color: "#000000" }}>
                    {item.question}
                  </label>
                  {showCanva ? (
                    <div className="custom-Draw-upload-container" style={{ height: isMobile ? "200px" : "220px" }}>
                      <div className="custom-cfd-Draw" style={{ width: "100%", height: "100%" }}>
                        <p
                          style={{ color: "#000000", cursor: "pointer" }}
                          onClick={handleCanva}
                        >
                          Clear
                        </p>
                        <DrawingArea onSvgFileChange={(svgFile) => updateSvgDataFields(index, svgFile)} />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="custom-Draw-upload-container"
                      style={{
                        background: "linear-gradient(to bottom, rgba(212, 243, 183, 0.2), rgba(35, 160, 141, 0.2))",
                        border:isMobile ? "1px solid rgba(0, 0, 0, 0.4)" : "1px solid #000000", height: isMobile ? "200px" : "220px"
                      }}
                    >
                      {!isMobile &&
                        <>
                        <img src={Ellipse2} alt="Bottom Left" className="circle-image2 circle-bottom-left" />
                      <img src={Ellipse1} alt="Top Right" className="circle-image1 circle-top-right" />

                      </>}
                      <div
                        className="custom-cfd-upload-action"
                        style={{
                          width: "100%",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          padding: "25px 0 20px 0",
                          lineHeight: "1",
                        }}
                      >
                        <svg width="40" height="40" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleCanva} style={{ cursor: "pointer" }}>
                          <path d="M0 46.5949V56.3891C0 57.2912 0.70879 58 1.61089 58H11.4051C11.8239 58 12.2427 57.8389 12.5327 57.5167L47.7145 22.3672L35.6328 10.2855L0.483267 45.4351C0.161089 45.7573 0 46.1439 0 46.5949ZM57.0576 13.024C58.3141 11.7675 58.3141 9.73781 57.0576 8.48132L49.5187 0.942369C48.2622 -0.314123 46.2325 -0.314123 44.976 0.942369L39.0801 6.83822L51.1618 18.9199L57.0576 13.024Z" fill="#22A08D" />
                        </svg>
                      </div>
                    </div>
                  )}
                </>
              ) : item.selectedValue === "RECORD" ||item.selectedValue === "Audio - Record"  ? (
                <>
                  <label style={{ margin: "5px 0 5px 0", color: "#000000" }}>
                    {item.question}
                  </label>
                  <div className="custom-live-voice-container">
                    {item.answer ?
                       <>
                       <audio src={URL.createObjectURL(item.answer)} controls style={{ width: isMobile ? "90%" : "50%" }} />
                       <i
                       className="far fa-times-circle fa-lg audio-remove-icon"
                       onClick={() => {
                         removeAnswer(index);
                       }}
                       style={{ color: "grey" ,cursor:"pointer",position:"relative"}}
                     ></i></>
                      : <AudioRecorder onSaveAudio={handleSaveAudio} index={index} onDeleteAudio={handleDeleteAudio} />}
                  </div>
                </>
              ) : item.selectedValue === "TEXT (.TXT)"|| item.selectedValue === "TEXT - Write-In"  && !hasAudioOrImage ? (
                <>
                  <label style={{ margin: "5px 0 10px 0" }}>
                    Upload data to this project
                  </label>
                  <div className="custom-cfd-upload" style={{ height: isMobile ? "150px" : "220px" }}>
                    {state.image ? (
                      <>
                       <img
                        src={state.renderImage}
                        alt="uploaded"
                        style={UploadedimageStyle}
                      />
                      <i
                      className="far fa-times-circle fa-lg image-remove-icon"
                      onClick={handleRemoveImage}
                      style={{ color: "grey" ,cursor:"pointer"}}
                    ></i></>
                     
                    ) : (
                      <>
                        <div
                          className="text-center"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            // position: "relative",
                          }}
                        >
                          {!isMobile && 
                          <>
                                                    <img src={Ellipse2} alt="Bottom Left" className="circle-image2 circle-bottom-left" />
                          <img src={Ellipse1} alt="Top Right" className="circle-image1 circle-top-right" />
</>}
                          <div className="d-flex flex-column">
                            <label htmlFor="file" id="filelabel">
                              <h1 className="mb-3" style={{color:"rgba(32, 159, 140, 1)",cursor:"pointer"}}>+</h1>
                            </label>
                            <input
                              type="file"
                              id="file"
                              name="dataUpload"
                              onChange={handleImageUpload}
                              style={{ display: "none" }}
                              accept=".png,.jpg,.jpeg"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <CustomInputField
                      type="text"
                      label={item.question}
                      className="CustomInputFieldstyles"
                      labelStyles={{ color: "#000000", margin: "5px" }}
                      name="prompt1"
                      value={item.answer ? item.answer : ""}
                      placeholder="Enter info..."
                      style={{ fontFamily: "DM Sans", border: "1px solid rgba(0, 0, 0, 0.4)" }}
                      onChange={(event) => updateDataFields(event, index)}
                    />
                  </div>
                </>
              ) : (
                <div style={{ marginTop: "10px" }}>
                  <CustomInputField
                    type="text"
                    label={item.question}
                    className="CustomInputFieldstyles"
                    name="prompt1"
                    labelStyles={{ color: "#000000", margin: "5px" }}
                    value={item.answer ? item.answer : ""}
                    placeholder="Enter info..."
                    style={{ fontFamily: "DM Sans", border: "1px solid rgba(0, 0, 0, 0.4)" }}
                    onChange={(event) => updateDataFields(event, index)}
                  />
                </div>
              )}
            </div>
          ))
        ) : (
          <>
            <label style={{ margin: "20px 0", fontWeight: "400", color: "#000000" }}>Upload data to this project.</label>
            <div className="custom-cfd-upload" style={{ height: isMobile ? "150px" : "220px" }}>
              {state.image ? (
                <>
                 <img
                src={state.renderImage}
                alt="uploaded"
                style={UploadedimageStyle}
              />
              <i
              className="far fa-times-circle fa-lg image-remove-icon"
              onClick={handleRemoveImage}
              style={{ color: "grey" ,cursor:"pointer"}}
            ></i></>
               
              ) : (
                <>
                  <div className="custom-cfd-img-input">
                    {!isMobile &&
                     <>
                      <img src={Ellipse2} alt="Bottom Left" className="circle-image2 circle-bottom-left" />
                    <img src={Ellipse1} alt="Top Right" className="circle-image1 circle-top-right" />
                    </>
                    }
                    <div className="d-flex flex-column">
                      <label htmlFor="file" id="filelabel">
                        <h3 className="custom-small-font" style={{color:"rgba(32, 159, 140, 1)",cursor:"pointer"}}>
                          +
                        </h3>
                      </label>
                      <input
                        type="file"
                        id="file"
                        name="dataUpload"
                        onChange={handleImageUpload}
                        style={{ display: "none" }}
                        accept=".png,.jpg,.jpeg"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );

  const SecondaryCard = () => (
    <div className="SecondaryCardContainer">
      <label style={labelStyles}>
        Provenance is the chronology of an object’s ownership.
      </label>
      <div className="custom-SecondaryCardContainer"
        style={{
          maxHeight: state?.dataFields.length > 0 && hasAnswerWithExtension ||
            state.image ? "80%" : "78%"
        }}
      >
        {(state.dataFields.length > 0 && hasAnswerWithExtension) ||
          state.image ? (
          <div
            className="custom-cfd-upload"
            style={{
              border: "none",
              backgroundColor: "transparent",
              flexDirection: "column",
              height: isMobile ? "150px" : "220px"
            }}
          >
            {state.image ?
  <img src={URL?.createObjectURL(state.image)} alt="uploaded_image" style={UploadedimageStyle} />
  :  <img src={getMediaImage(mediaURL)} alt="uploaded_audio" style={UploadedimageStyle} />
}
<label style={{ margin: "5px" }}>
            {
            state?.image ? state.image.name : mediaURL ? mediaURL?.name : ""}{" "}
            - ({isAuth()?.username ? isAuth().username : "Anonymous"})
          </label>
          </div>
        ) : (
          <div className="custom-cfd-upload" style={{ height: isMobile ? "150px" : "220px" }}>
            <div
              className="custom-cfd-actions"
              style={{ width: "100%", alignItems: "center" }}
            >
              <label
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: isMobile ? "16px" : "18px"
                }}
              >
                No uploaded files, please continue.
              </label>
            </div>
          </div>
        )}
        <div className="custom-screenTwoCover">
          {(state?.dataFields.length > 0 && hasAnswerWithExtension) ||
            state.image ? (
            <div className="custom-cfd-questions2" style={{ padding: "0", height: isMobile && "160px" }}>
              <CustomInputField
                label="Author Or Attribution"
                type="text"
                name="author"
                placeholder="Enter info..."
                labelStyles={{ color: "#000000", margin: "5px"}}
                value={state.author}
                style={{ fontFamily: "DM Sans", border: "1px solid rgba(0, 0, 0, 0.4)", background: "transparent" }}
                onChange={(event) => setStateData(event)}
              />
              <CustomInputField
                label="How was this sourced?"
                type="text"
                name="howWasThisSourced"
                placeholder="Enter info..."
                labelStyles={{ color: "#000000", margin: "5px" }}
                value={state.howWasThisSourced}
                style={{fontFamily: "DM Sans",border: "1px solid rgba(0, 0, 0, 0.4)",background: "transparent"}} 
                onChange={(event) => setStateData(event)}
              />
            </div>
          ) : null}
          {state.optionalFields.length > 0
            ? state.optionalFields.map((item, index) => (
              <div key={index}style={{ margin: "10px 0", padding: isMobile ? "0 4px" : "0 8px" }}>
                <CustomInputField
                  key={index.toString()}
                  label={item.question}
                  type="text"
                  labelStyles={{ color: "#000000" }}
                  name="prompt1"
                  style={{ backgroundColor: "transparent" }}
                  placeholder="Enter info..."
                  value={state.question}
                  onChange={(event) => updateOptionalfields(event, index)}
                />
              </div>
            ))
            : null}
        </div>
      </div>
    </div>
  );

  const ThirdCard = () => (
    <div className="ThirdCardContainer">
      <label style={labelStyles}>
        {isMobile ? " * indicates a required field" : `Complete your contribution to the project by providing your consent
        below.`
        }
       
      </label>
      {/* <div className="cfd-questions">
        <CustomInputField
          label="EMAIL (OPTIONAL)"
          type="text"
          name="email"
          placeholder="Enter info..."
          value={state.email}
          onChange={(event) => setStateData(event)}
        />
        <CustomInputField
          label="DONATION OR PAID"
          type="text"
          name="donationOrPaid"
          placeholder="Enter info..."
          value={state.donationOrPaid}
          onChange={(event) => setStateData(event)}
        />
      </div> */}
      {!isMobile &&
        <div>
        <label
          style={{
            marginBottom: "15px",
            fontSize: isMobile ? "12px" : "14px",
            fontWeight: "bold",
            color: "rgba(0,0,0,0.7)",
          }}
        >
          * indicates a required field
        </label>
      </div>}

      <div className="custom-cfd-confirm-container1">
        <label style={{...labelConfirm1}} >
          <input
            type="checkbox"
            name="permissionTwo"
            value="I consent to this data being used according to the data
                 settings of this project, for as long as the project
                 chooses to store the data"
            onChange={setPermissions}
            style={{ height: "18px",width:isMobile ? "45px":"28px",marginTop:"5px"}}
          />
          <span className="custom-spanconfirm" style={{textTransform:"none",color:"rgba(32, 159, 140, 1)"}}>
          I consent to this data being used according to the data settings of this project, for as long as the project chooses to store the data*
          </span>
        </label>
        <LocationComponent onLocationDataChange={handleLocationDataUpdate} containerStyles ={{flexDirection:"row",alignItems:"center",gap:"10px"}} style={{marginTop:"3px",marginRight:"1px"}} top="15%" left="32%"/>
        <label style={labelConfirm}>
          <input
            type="checkbox"
            name="permissionThree"
            onChange={setPermissions}
            value="CAN WE FOLLOW-UP WITH YOU ABOUT YOUR UPLOAD?"
            style={{ height: "18px",width:isMobile? "22px":"18px" }}
          />
          CAN WE FOLLOW-UP WITH YOU ABOUT YOUR UPLOAD?
        </label>
        <label style={labelConfirm}>
          <input
            type="checkbox"
            name="termsAndCondition"
            onChange={setPermissions}
            value="CAN WE FOLLOW-UP WITH YOU ABOUT YOUR UPLOAD?"
            style={{ height: "18px",width:isMobile? "22px":"18px" }}
          />
       I agree to POIETO’s <span onClick={() => window.open('https://app.termly.io/policy-viewer/policy.html?policyUUID=7a3a2bcf-2bfa-4ab7-9f9f-cd37818c05da', '_blank')}style={{textDecoration:"underline",marginLeft:"5px",cursor:"pointer"}}>Terms and Conditions.</span>
        </label>
      </div>
     
     
      <label style={labelStyles}>
        {isMobile && "Complete your contribution to the project by providing your consent below."
        }
      </label>
    </div>
  );

  const FourthCard = () => (
    <React.Fragment>
      <div className="custom-cfd-fourth-card">
        <label style={{ fontSize: "24px" }}> Success! </label>
        <label style={{ fontSize: "18px",display:"flex",gap:"10px" }}>
        Your submission to
          <div className="d-flex align-items-center greenBox">
            {parentStates.projName.toUpperCase()}
          </div> has been received.{" "}
        </label>
      </div>
    </React.Fragment>
  );
  const mobileFourthCard = () => (
    <React.Fragment>
      <div className="custom-cfd-mobile-fourth-card">
        <label style={{...labelStyles, fontSize: "18px" }}>
        Thank you for your submission!
        </label>
        <div className="signup-updates">
            <label style={{...labelStyles, fontSize: "18px" }}>Sign-up for updates </label>
            <input
            type="text" 
            placeholder="Your email..."/>
        </div>
      </div>
    </React.Fragment>
  );

  const showCards = () => {
    switch (currentCard) {
      case 1:
        return initialCard();
      case 2:
        return SecondaryCard();
      case 3:
        return ThirdCard();
      case 4:
        return FourthCard();
      default:
        return initialCard();
    }
  };

  const showMobileCards = () => {
    switch (currentCard) {
      case 1:
        return infoContainer();
      case 2:
        return initialCard();
      case 3:
        return SecondaryCard();
      case 4:
        return ThirdCard();
        case 5:
          return mobileFourthCard();
      default:
        return infoContainer();
    }
  };

  const labelStyle = {
    margin: "5px 0",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 1)" // correct
  }



  const handleOnClick = () => {
    if ((isMobile && currentCard === 4) || (!isMobile && currentCard === 3)) {
      if (!validateInput(state.permissionTwo, "consent") 
              || (!validateInput(state.termsAndCondition,"terms"))) {
        return;
      }
      submitForm();
      setTimeout(() => {
        handleNext();
      }, 4000);
    } else {
      handleNext();
    }
  };
  const borderStyle = (cardNumber) => ({
    // borderBottom: currentCard === cardNumber ? '3px solid #209f8c' : 'none',
    margin: '10px 0',
    width: "80%",
  });
  const getLabelStyle = (cardNumber) => ({
    color: (currentCard === cardNumber || currentCard === cardNumber || currentCard === cardNumber) ? '#000000' : '#4E4E4EB2'
  });

  const [isExpanded, setIsExpanded] = React.useState(false);
  const charLimit = screenHeight - 400;
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const steps = [
    { label: 'DATA', card: 2 },
    { label: 'PROVENANCE', card: 3 },
    { label: 'CONSENT & PERMISSION', card: 4 }
  ];

 

  return (
    <>
    {isMobile ? 
      <div
      className="custom-cfd-main-container"
      style={{ width: screenWidth - 20, height:screenHeight - 55}}
    >
      <div className="custom-cfd-container">
        <div className="custom-cfd-Headers" style={{height:"100%"}}>
          {currentCard < 5 && currentCard > 1 ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {steps.filter(step => step.card === currentCard).map((step, index) => (
                <div key={index} className="step" style={{ ...borderStyle(step.card), textAlign: "center" }}>
                  <label className={`step-label ${currentCard === step.card ? 'active' : ''}`} style={{ ...getLabelStyle(step.card) }}>
                    {step.label}
                  </label>
                </div>
            ))}
          </div>
          ) : null}
          {showMobileCards()}
        </div>
        {currentCard <= 4 && currentCard > 1? (
          <div className="custom-cfd-button-group" style={{ justifyContent: currentCard === 1 ? "center" : "space-between",padding:"0"  }}>
              <button className="custom-cfd-back-action"
              style={{background:"transparent"}}
                onClick={handleBack}>
                Back
              </button>
            <button
              className="custom-cfd-next-action"
              onClick={() => handleOnClick()}
              disabled={state.isSubmitting}
              style={{ width: currentCard === 1 ? '100%' : '49%',background:!hasAnswerWithExtension ? "rgba(0, 0, 0, 0.54)":"black" }}
            >
              {currentCard === 4 ? isLoading ? "Submitting" : "Submit" : "Next"}
            </button>
          </div>
        ) : (
          <div
            className="custom-cfd-button-group"
            style={{zIndex:"1",padding:"0" }}
          >
            {currentCard === 1 && 
              <button
              className="custom-cfd-next-action"
              style={{ width: "100%" ,background:"black"}}
              onClick={handleNext}
            >
              Donate
            </button>}
          </div>
        )}
      </div>
    </div> :   <div
      className="custom-cfd-main-container"
      style={{ width: screenWidth - 20, height: isMobile ? screenHeight - 60 : screenHeight - 100, zIndex: "1" }}
    >
      <div className="custom-cfd-info-container">
        <div className="d-flex align-items-center greenBox">
          {parentStates.projName.toUpperCase()}  
        </div>
        <label style={{ ...labelStyle, fontSize: "32px", color: "#000000", fontWeight: "400" }}>
          {parentStates?.DataCallTitle?.toUpperCase()}
        </label>
        <label style={labelStyle}>THE CALL</label>
        <label style={{ ...labelStyle, textAlign: "justify", fontSize: "20px" }}>
          {parentStates?.title}
        </label>
        <div className="custom-cfd-side-Proj">
          <label style={{ marginTop: "20px", fontSize: "14px", color: "#000000" }}>
            PROJECT DESCRIPTION
          </label>
          {renderMessage()}
          {isExpanded && parentStates.message.length > charLimit && (
            <span
              onClick={toggleReadMore}
              style={{
                color: '#000000',
                cursor: 'pointer',
                fontSize: '16px',
                display: 'block',
                marginTop: '10px',
                fontFamily: "DM Sans"
              }}
            >
              Show less
            </span>
          )}
          <br></br>
          <br></br>
          <label style={{ marginBottom: "10px", fontSize: "14px",color:"#000000"}}>
          DATA SETTINGS
          </label>
          <div className="Data-Privacy-container">
            <label style={{...labelStyle,margin:"2px"}}>Privacy</label>
            <label style={{color:'#000000'}}>{parentStates?.privacy}</label>
            <label style={{color:"rgba(0, 0, 0, 0.7)"}}>
  {parentStates?.privacy === "PRIVATE" 
    ? "Project is listed privately in our project" 
    : <>Project is listed publicly in our project database <br/> and others can request to join.</>}
</label>
          </div>
          {parentStates?.contributionTypes?.length > 0 &&
           <label style={{...labelStyle,margin:"2px"}}>Contribution Type</label>}
         
          {parentStates?.contributionTypes && parentStates?.contributionTypes.map((type, index) => (
  <div className="Data-Privacy-container" key={index} style={{margin:"0"}}>
    <label style={{color:'#000000'}}>{type}</label>
  </div>
))}
        </div>
      </div>
      <div className="custom-cfd-container">
        <div className="custom-cfd-Headers">
          {currentCard < 4 ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div className="step" style={{ ...borderStyle(1), textAlign: "center" }}>
                <label className={`step-label ${currentCard === 1 ? 'active' : ''}`} style={{ ...getLabelStyle(1) }}>DATA</label>
              </div>
              <div className="step" style={{ ...borderStyle(2), textAlign: "center" }}>
                <label className={`step-label ${currentCard === 2 ? 'active' : ''}`} style={{ ...getLabelStyle(2) }}>PROVENANCE</label>
              </div>
              <div className="step" style={{ ...borderStyle(3), textAlign: "center" }}>
                <label className={`step-label ${currentCard === 3 ? 'active' : ''}`} style={{ ...getLabelStyle(3) }}>{isMobile ? "CON & PER" : "CONSENT & PERMISSION"} </label>
              </div>
            </div>
          ) : null}
          {showCards()}
        </div>
        {currentCard < 4 ? (
          <div className="custom-cfd-button-group" style={{ justifyContent: currentCard === 1 ? "center" : "space-between" }}>
            {currentCard > 1 &&
              <button className="custom-cfd-back-action"
                onClick={handleBack}>
                Back
              </button>
            }
            <button
              className="custom-cfd-next-action"
              onClick={() => handleOnClick()}
              disabled={state.isSubmitting}
              style={{ width: currentCard === 1 ? '100%' : '49%',backgroundColor:!hasAnswerWithExtension ? "#56B3A3":"rgba(32, 159, 140, 0.54)"}}
            >
              {currentCard === 3 ? state.isSubmitting ? "Submitting" : "Submit" : "Next"}
            </button>
          </div>
        ) : (
          <div
            className="custom-cfd-button-group"
            style={{ justifyContent: "center" }}
          >
            <button
              className="custom-cfd-next-action"
              style={{ width: "80%",backgroundColor:"rgba(212, 243, 183, 1)",color:"rgba(0, 0, 0, 0.7)" }}
              onClick={close}
            >
             Sign up for Beta Access!
            </button>
          </div>
        )}
      </div>
    </div>}
    </>
  
  );
};

export default CustomUploadData;
