import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, useLocation } from "react-router-dom";
import App from './App';
import Navbar from './Components/Navbar/navbar';
import { MenuProvider } from './Components/Navbar/menuContext';

// Disable logs in production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
}

const AppWrapper = () => {
  const location = useLocation();
  const hideNavbarPaths = ['/project-dbse'];

  const shouldHideNavbar = hideNavbarPaths.includes(location.pathname);

  return (
    <>
      {!shouldHideNavbar && <Navbar />}
      <App />
      <Toaster />
    </>
  );
};

// Use ReactDOM.createRoot for rendering
const root = ReactDOM.createRoot(document.getElementById('root')); // Changed to createRoot
root.render(
  <React.StrictMode>
    <MenuProvider>
      <BrowserRouter>
        <AppWrapper />
      </BrowserRouter>
    </MenuProvider>
  </React.StrictMode>
);
